#main-wrapper {
  clear: both;
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

#page-wrapper {
  // min-height: 100vh;
  overflow: hidden;
  display: block;
  position: relative;
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
}


.no-sidebar {
  #content {
    @extend .row-full-width;
  }
}


body.sidebar {
  #main-wrapper {
    max-width: $width--large;
    margin: 0 auto !important;
  }
  #main {
    padding-left: 15px;
    padding-right: 15px;
    @include breakpoint($bp--medium-up) {
      padding-left: 50px;
      padding-right: 50px;
    }
  }
}


body.sidebar--right {
  #sidebar {
    margin-top: 75px;
    @include breakpoint($bp--medium-up) {
      width: 27.21%;
      clear: right;
      float: right;
      margin-top: 0;
    }
  }
  #content {
    @include breakpoint($bp--medium-up) {
      width: 63.60%;
      clear: right;
      float: left;
    }
  }
}

body.sidebar--left {
  #sidebar {
    margin-top: 75px;
    @include breakpoint($bp--medium-up) {
      width: 27.21%;
      clear: left;
      float: left;
      margin-top: 0;
    }
  }
  #content {
    @include breakpoint($bp--medium-up) {
      width: 63.60%;
      clear: right;
      float: right;
    }
  }
}

.sidebar-color--grey {
  .sidebar {
    background-color: #EDEDED;
  }
}




// .node-form {
//   .field-name-field-body {
//     margin-bottom: 0;
//   }
// }
// .field-name-field-body {
//   margin-bottom: 50px;
//   overflow: hidden;
//   clear: both;
// }


// .page-node-edit .field-name-field-body {
//   margin-bottom: 0;
// }



// // .wysiwyg_threecols {
// //   @extend %clearfix;
// //   &.layout--33-33-33 {
// //     @include breakpoint($bp--medium-up) {
// //       & > *:nth-child(1) {
// //         width: 32%;
// //         float: left;
// //       }
// //       & > *:nth-child(2) {
// //         width: 32%;
// //         margin-left: 2%;
// //         float: left;
// //       }
// //       & > *:nth-child(3) {
// //         width: 32%;
// //         margin-left: 2%;
// //         float: left;
// //       }
// //     }
// //   }

// //   &.layout--60-20-20 {
// //     @include breakpoint($bp--medium-up) {
// //       & > *:nth-child(1) {
// //         width: 58%;
// //         float: left;
// //       }
// //       & > *:nth-child(2) {
// //         width: 19.5%;
// //         margin-left: 1.5%;
// //         float: left;
// //       }
// //       & > *:nth-child(3) {
// //         width: 19.5%;
// //         margin-left: 1.5%;
// //         float: left;
// //       }
// //     }
// //   }
// //   &.layout--20-60-20 {
// //     @include breakpoint($bp--medium-up) {
// //       & > *:nth-child(1) {
// //         width: 19.5%;
// //         float: left;
// //         margin-right: 1.5%;
// //       }
// //       & > *:nth-child(2) {
// //         width: 58%;
// //         float: left;
// //       }
// //       & > *:nth-child(3) {
// //         width: 19.5%;
// //         margin-left: 1.5%;
// //         float: left;
// //       }
// //     }
// //   }
// //   &.layout--20-20-60 {
// //     @include breakpoint($bp--medium-up) {
// //       & > *:nth-child(1) {
// //         width: 19.5%;
// //         float: left;
// //         margin-right: 1.5%;
// //       }
// //       & > *:nth-child(2) {
// //         width: 19.5%;
// //         margin-left: 1.5%;
// //         float: left;
// //       }
// //       & > *:nth-child(3) {
// //         width: 58%;
// //         float: left;
// //       }
// //     }
// //   }
// // }
