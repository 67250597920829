#logo {
  float: left;
  line-height: 85px;
  margin-top: 0;

  @include breakpoint($bp--medium-up) {
    line-height: 77px;
    margin-top: 30px;
  }

  img {
    width: auto;
    vertical-align: middle;
    margin: 20px 0;
    max-width: 240px;
    max-height: 100px;
    @include breakpoint($bp--tablet-up) {
      max-width: 300px;
      margin: 0;
    }
    @include breakpoint($bp--960-up) {
      max-width: 332px;
      margin: 0;
    }
  }
}

.logo ~ #header {
  padding-bottom: 0px;
  @include breakpoint($bp--medium-up) {
    padding-bottom: 30px;
  }
}

#header {
  // -moz-transition: all 0.5s ease-in-out;
  // -ms-transition: all 0.5s ease-in-out;
  // -o-transition: all 0.5s ease-in-out;
  // transition: all 0.5s ease-in-out;
  padding-bottom: 30px;
  .nav-toggle {
    width: 40px;
    height: 40px;
    margin-top: 25px;
    //text-indent: -9999px;
    float: right;
    z-index: 201;
    position: relative;
    background: transparent;
    color: $color--navy;
    .fa-bars {
      font-size: 2em;
    }
    &.active {
      color: $color--blue;
      .fa-bars::before {
        content: "\f00d";
      }
    }
  }
  .block-search {
    float: right;
    padding-top: 10px;
    padding-bottom: 36px;
    position: relative;
    display: none;
    height: 75px;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    @include breakpoint($bp--medium-up) {
      display: block;
    }
    .form-actions {
      display: none;
    }
  }
  #block-ucsf-b1gfoot-tweaks-header-socialmedia {
    float: right;
    margin-top: 13px;
    margin-right: 35px;
    display: none;
    @include breakpoint($bp--medium-up) {
      display: block;
    }
    .content {
      overflow: hidden;
    }
  }

  .block-menu-block {
    clear: right;
    float: right;
    position: relative;
    overflow: visible;

    a,
    a:active {
      -moz-transition: none;
      -webkit-transition: none;
      transition: none;
    }

    &.opened {
      float: none;
      clear: none;
      .menu-block-wrapper > ul {
        width: 90%;
        background-color: $color--grey1--tint2;
        z-index: 201;
        li {
          display: block;
          a {
            text-align: left;
          }
        }
      }
    }
    .menu-block-wrapper > ul {
      li:hover {
        > ul {
          @include breakpoint($bp--medium-up) {
            display: block !important;
          }
        }
        // background-color: lighten($color--orange, 20%);
      }
      i.fa-caret-down {
        padding-left: 6px;
        vertical-align: middle;
        font-size: 20px;
      }
      li.last {
        margin-right: 0;
      }
      li.active > a {
        @include breakpoint($bp--medium-up) {
          // background-color: #E6E6E6;
          // font-weight: bold;

        }
      }
      li.active-trail > a {
        @include breakpoint($bp--medium-up) {
          // background-color: #E6E6E6;
          // font-weight: bold;
        }
      }
      li {
        width: auto;
        display: inline-block;
        text-align: right;
        //border: 1px solid transparent;
        position: relative;
        margin-right: 20px;

        @include breakpoint($bp--medium-up) {
          padding: 5px 0px;
          margin-right: 50px;
          border: none;
          border-bottom: 2px solid transparent;
        }
        a {
          color: $color--navy;
          display: block;
          text-decoration: none;
          font-family: $main-font;
        }
        ul {
          width: 200px;
          border-top: none;
          -webkit-border-radius: 4px;
          -webkit-border-bottom-right-radius: 0px;
          -webkit-border-bottom-left-radius: 0px;
          -moz-border-radius: 4px;
          -moz-border-radius-bottomright: 1px;
          -moz-border-radius-bottomleft: 1px;
          border-radius: 4px;
          border-bottom-right-radius: 0px;
          border-bottom-left-radius: 0px;
          left: 0px;
          padding-top: 10px;
          // max-height: 100px;
          -webkit-box-shadow: 0px 6px 12px -4px rgba(0,0,0,0.16);
          -moz-box-shadow: 0px 6px 12px -4px rgba(0,0,0,0.16);
          box-shadow: 0px 6px 12px -4px rgba(0,0,0,0.16);
          z-index: 100;
          display: block;
          position: absolute;
          @include breakpoint($bp--medium-up) {
            display: none;
          }
          li {
            width: 100%;
            background-color: #EFEFEF;
            text-align: left;
            font-family: $main-font;
            border-bottom: 1px solid $color--grey2;
            &:last-child {
              border-bottom: none;
            }
            @include border-radius(0);
            &:hover {
              background-color: $color--grey1--tint1;
            }
            a {
              padding-top: 14px;
              padding-left: 12px;
              padding-bottom: 14px;
            }
          }
        }
        &.last {
          ul {
            left: auto;
            right: 0;
          }
        }
      }
      li.expanded {
        @include breakpoint($bp--medium-up) {
          padding: 8px 22px 8px 5px;
        }
        &:before {
          float: right;
          position: relative;
          top: 9px;
          right: -16px;
          line-height: 0em;
          display: none;
          @include breakpoint($bp--medium-up) {
            display: inline-block;
          }
        }
      }
    }
  }

}

@include breakpoint($bp--small-down) {
  #header .block-menu-block {
    position: relative;
    z-index: 201;
    .menu-block-wrapper > ul {
      position: absolute;
      margin-left: -466px;

      -webkit-transition: none;
      -moz-transition: none;
      -ms-transition: none;
      -o-transition: none;
      transition: none;

      -webkit-box-shadow: 2px 2px 2px #CCC;
      -moz-box-shadow: 2px 2px 2px #CCC;
      box-shadow: 2px 2px 2px #CCC;

      & > li:first-child {
        border-top: 0;
      }

      li {
        display: block;
        padding: 0;
        margin: 0;
        overflow: hidden;
        width: 100%;
        border-bottom: 1px solid $color--grey2;
        border-top: 1px solid $color--grey2;
        margin-bottom: -1px;
        .arrow-right, .arrow-down {
          display: inline-block;
          width: 60px;
          height: 100%;
          padding: 10px;
          text-align: center;
          font-size: 1em;
          position: absolute;
          right: 0;
          top: 0;
          background-color: $color--grey1;
          z-index: 300;
          padding-top: 20px;
          cursor: pointer;
          &::before {
            font-family: FontAwesome;
            content: "\f105";
          }
          //background: url("../img/right-arrow.png") no-repeat center $color--grey1;
        }
        .arrow-down {
          //background: url("../img/down-arrow.png") no-repeat center $color--grey1;
          &::before {
            font-family: FontAwesome;
            content: "\f107";
          }
        }
        a {
          padding: 20px;
          padding-right: 45px;
          display: block;
        }
        &:hover {
          background-color: transparent !important;
        }
        ul {
          width: 100%;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          box-shadow: none;
          display: inline-block;
          padding: 0;
          margin: 0;
          position: static;
          border-top: 1px solid $color--grey2;
          &.show {
            display: block !important;
          }
          li {
            border: none;
            a {
              padding: 20px;
              padding-right: 45px;
              padding-left: 30px;
            }
          }
        }
      }
    }
  }
}
.nav--lock {
  #header.page-scrolling {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    max-height: 100%;
    z-index: 999;
    background: $color--white;
    padding-bottom: 0;
    box-shadow: 1px 1px 8px rgba(102,102,102,0.3);
    .inside {
      height: 60px;

      #block-search-form,
      #block-ucsf-b1gfoot-tweaks-header-socialmedia {
        display: none;
      }
      #block-menu-block-1 {
        margin-top: 13px;
      }
    }
    #site-name {
      height: 60px;
      padding-top: 20px;
      @include breakpoint($bp--small-up) {
        padding-top: 8px;
        line-height: 50px;
      }
      @include breakpoint(0 $bp--xsmall-up) {
        padding-top: 5px;
      }
    }
    .nav-toggle {
      margin-top: 0;
    }
    #logo {
      line-height: 60px;
        margin-top: 0;
        img {
          height: 40px;
          max-height: 100%;
      }
    }
  }

  .page-scrolling-space {
    height: 150px;
    display: none;
  }
  &.admin-menu #header.page-scrolling {
    top: 34px;
  }

}

#site-name {
  float: left;
  font-size: 26px;
  font-weight: $font--light;
  padding-top: 40px;
  margin-bottom: 0;
  font-family: $main-font--light;
  width: 290px;
  position: relative;
  height: 105px;
  // -moz-transition: all 0.25s ease-in-out;
  // -ms-transition: all 0.25s ease-in-out;
  // -o-transition: all 0.25s ease-in-out;
  // transition: all 0.25s ease-in-out;

  letter-spacing: 0.5px;
  line-height: 1;
  @include breakpoint(0 $width--medium) {
    padding-top: 30px;
    float: left;
    width: 80%;
    height: auto;
  }
  a {
    color: #052049;
    position: absolute;
    bottom: 2px;
    @include breakpoint(0 $width--medium) {
      position: static;
    }
  }
}
