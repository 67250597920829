.page-overlay {
  width: 100%;
  height: 100%;
  background-color: #FFF;
  opacity: 0.61;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
}
