.filter-wrapper {
  label {
    display: inline-block;
    margin-right: 10px;
  }
}
.filter-guidelines {
  .tips {
    display: none;
  }
  &.show .tips {
    display: block;
  }
}
.filter-help {
  float: right;
  p {
    margin: 0;
  }
  a {
    background: url(/misc/help.png) no-repeat center center;
    text-indent: -9999px;
    display: block;
    width: 16px;
  }
}

#module-filter-modules table {
  position: relative;
}