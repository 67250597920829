.region-content .field-type-text-long,
.region-content .field-type-text-with-summary,
.cke_editable {
  img[style*="left"] {
    margin-right: 30px;
    margin-bottom: 30px;
    @include breakpoint(0 $width--small) {
      width: 100%;
      height: 100%;
    }
  }
  img[style*="right"] {
    margin-left: 30px;
    margin-bottom: 30px;
    @include breakpoint(0 $width--small) {
      width: 100%;
      height: 100%;
    }
  }
  figure {
    display: table;
    // width: 1px;
  }
  .wysiwyg_twocols figure,
  .wysiwyg_threecols figure {
    width: 100%;
    table-layout: fixed;
  }

  figure.image[style*="left"] {
    margin-right: 30px;
    margin-bottom: 30px;
    @include breakpoint(0 $width--small) {
      margin-right: 0;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  figure.image[style*="right"] {
    margin-left: 30px;
    margin-bottom: 30px;
    @include breakpoint(0 $width--small) {
      margin-left: 0;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  figure.image[style*="inline-block"] {
    margin-left: auto;
    margin-right: auto;
    display: table !important;
    text-align: left;
    @include breakpoint(0 $width--small) {
      margin-left: 0;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.region-sidebar {
  .block-block {
    img[style*="left"] {
      margin-right: 15px;
      margin-bottom: 15px;
    }
    img[style*="right"] {
      margin-left: 15px;
      margin-bottom: 15px;
    }
    figure {
      display: table;
      // width: 1px;
    }
    figure.image[style*="left"] {
      margin-right: 15px;
      margin-bottom: 15px;
    }
    figure.image[style*="right"] {
      margin-left: 15px;
      margin-bottom: 15px;
    }
  }
}


.wysiwyg_featured_content {
  .wysiwyg_featured_content--image_area {
    p {
      margin: 0;
    }
    a {
      display: block;
      line-height: 0;
      &:hover {
        opacity: 0.5;
      }
      img {
        display: block;
        line-height: 0;
      }
    }
  }
  .wysiwyg_featured_content--body {
    h3 {
      margin-bottom: 5px;
      margin-top: 18px;
    }
  }
}

.region-content,
.region-footer,
.cke_editable {
  .wysiwyg_threecols,
  .wysiwyg_twocols {
    @extend %clearfix;
    @include breakpoint($bp--medium-up) {
      display: flex;
    }

    img {
      max-width: 100%;
      height: auto;
    }
    & > div[class*="bg--"] {
      padding: 25px;
      margin-bottom: 20px;
    }
    & > div.bg--transparent {
      padding: 0;
      margin-bottom: 0;
    }
  }
  .wysiwyg_twocols {
    @extend %clearfix;
    &.layout--80-20 {
      @include breakpoint($bp--medium-up) {
        & > *:nth-child(1) {
          width: 78.5%;
          margin-right: 1.5%;
          float: left;
        }
        & > *:nth-child(2) {
          width: 18.5%;
          margin-left: 1.5%;
          float: right;
        }
      }
    }

    &.layout--70-30 {
      @include breakpoint($bp--medium-up) {
        & > *:nth-child(1) {
          width: 68.5%;
          margin-right: 1.5%;
          float: left;
        }
        & > *:nth-child(2) {
          width: 28.5%;
          margin-left: 1.5%;
          float: right;
        }
      }
    }
    &.layout--60-40 {
      @include breakpoint($bp--medium-up) {
        & > *:nth-child(1) {
          width: 58.5%;
          margin-right: 1.5%;
          float: left;
        }
        & > *:nth-child(2) {
          width: 38.5%;
          margin-left: 1.5%;
          float: right;
        }
      }
    }
    &.layout--50-50 {
      @include breakpoint($bp--medium-up) {
        & > *:nth-child(1) {
          width: 48.5%;
          margin-right: 1.5%;
          float: left;
        }
        & > *:nth-child(2) {
          width: 48.5%;
          margin-left: 1.5%;
          float: right;
        }
      }
    }
    &.layout--40-60 {
      @include breakpoint($bp--medium-up) {
        & > *:nth-child(1) {
          width: 38.5%;
          margin-right: 1.5%;
          float: left;
        }
        & > *:nth-child(2) {
          width: 58.5%;
          margin-left: 1.5%;
          float: right;
        }
      }
    }
    &.layout--30-70 {
      @include breakpoint($bp--medium-up) {
        & > *:nth-child(1) {
          width: 28.5%;
          margin-right: 1.5%;
          float: left;
        }
        & > *:nth-child(2) {
          width: 68.5%;
          margin-left: 1.5%;
          float: right;
        }
      }
    }
    &.layout--20-80 {
      @include breakpoint($bp--medium-up) {
        & > *:nth-child(1) {
          width: 18.5%;
          margin-right: 1.5%;
          float: left;
        }
        & > *:nth-child(2) {
          width: 78.5%;
          margin-left: 1.5%;
          float: right;
        }
      }
    }
  }
}

.region-content,
.region-footer,
.cke_editable {
  .wysiwyg_threecols {
    // margin-bottom: 30px;
    @extend %clearfix;
    &.layout--33-33-33,
    &.layout--33-33 {
      @include breakpoint($bp--medium-up) {
        & > *:nth-child(1) {
          width: 32%;
          float: left;
          &.bg--transparent {
            padding: 0;
          }
        }
        & > *:nth-child(2) {
          width: 32%;
          margin-left: 2%;
          float: left;
          &.bg--transparent {
            padding: 0;
          }
        }
        & > *:nth-child(3) {
          width: 32%;
          margin-left: 2%;
          float: left;
          &.bg--transparent {
            padding: 0;
          }
        }
      }
    }

    &.layout--60-20-20 {
      @include breakpoint($bp--medium-up) {
        & > *:nth-child(1) {
          width: 57%;
          float: left;
          &.bg--transparent {
            padding: 0;
          }
        }
        & > *:nth-child(2) {
          width: 19.5%;
          margin-left: 2%;
          float: left;
          &.bg--transparent {
            padding: 0;
          }
        }
        & > *:nth-child(3) {
          width: 19.5%;
          margin-left: 2%;
          float: left;
          &.bg--transparent {
            padding: 0;
          }
        }
      }
    }
    &.layout--20-60-20 {
      @include breakpoint($bp--medium-up) {
        & > *:nth-child(1) {
          width: 19.5%;
          float: left;
          margin-right: 2%;
          &.bg--transparent {
            padding: 0;
          }
        }
        & > *:nth-child(2) {
          width: 57%;
          float: left;
          &.bg--transparent {
            padding: 0;
          }
        }
        & > *:nth-child(3) {
          width: 19.5%;
          margin-left: 2%;
          float: left;
          &.bg--transparent {
            padding: 0;
          }
        }
      }
    }
    &.layout--20-20-60 {
      @include breakpoint($bp--medium-up) {
        & > *:nth-child(1) {
          width: 19.5%;
          float: left;
          margin-right: 2%;
          &.bg--transparent {
            padding: 0;
          }
        }
        & > *:nth-child(2) {
          width: 19.5%;
          margin-right: 2%;
          float: left;
          &.bg--transparent {
            padding: 0;
          }
        }
        & > *:nth-child(3) {
          width: 57%;
          float: left;
          &.bg--transparent {
            padding: 0;
          }
        }
      }
    }
  }
}
.bg--transparent {
  background-color: transparent;
}
.bg--white {
  background-color: #FFF;
}
.bg--grey {
  background-color: #cdd2db;
}
.bg--light-grey {
  background-color: $color--light-grey;
}
.bg--blue {
  background-color: #e8f4fa;
}
.bg--navy {
  background-color: #E6E9ED;
}
.bg--green {
  background-color: #F4F8EA;
}
.bg--teal {
  background-color: #e8f6f7;
}
.bg--lime {
  background-color: #f4f8ea;
}
.bg--orange {
  background-color: #fef2e9;
}
.bg--red {
  background-color: #eb093c;
}
.bg--purple {
  background-color: #716fb2;
}
.bg--interactive-blue {
  background-color: $color--blue;
  color: #fff;
  h1, h2, h3, h4 {
    color: #fff;
  }
  a {
    color: #fff;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}
.bg--interactive-dark-blue {
  background-color: $color--navy;
  color: #fff;
  h1, h2, h3, h4 {
    color: #fff;
  }
  a {
    color: #fff;
    text-decoration: underline;
    // border: 1px solid #fff;
    // padding: 5px 10px;
    // border-radius: 5px;
    // &:hover {
    //   background: rgba(255,255,255,0.3);
    // }
    &:hover {
      text-decoration: none;
    }
  }
}
.bg--interactive-light-navy {
  background-color: $color--navy--tint1;
  color: #fff;
  h1, h2, h3, h4 {
    color: #fff;
  }
  a {
    color: #fff;
    text-decoration: underline;
    // border: 1px solid #fff;
    // padding: 5px 10px;
    // border-radius: 5px;
    // &:hover {
    //   background: rgba(255,255,255,0.3);
    // }
    &:hover {
      text-decoration: none;
    }
  }
}
.bg--interactive-dark-grey {
  background-color: $color--dark-grey;
  color: #fff;
  h1, h2, h3, h4 {
    color: #fff;
  }
  a {
    color: #fff;
    text-decoration: underline;
    // border: 1px solid #fff;
    // padding: 5px 10px;
    // border-radius: 5px;
    // &:hover {
    //   background: rgba(255,255,255,0.3);
    // }
    &:hover {
      text-decoration: none;
    }
  }
}
.bg--interactive-green {
  background-color: $color--green;
  color: #fff;
  h1, h2, h3, h4 {
    color: #fff;
  }
  a {
    color: #fff;
    text-decoration: underline;
    // border: 1px solid #fff;
    // padding: 5px 10px;
    // border-radius: 5px;
    // &:hover {
    //   background: rgba(255,255,255,0.3);
    // }
    &:hover {
      text-decoration: none;
    }
  }
}
.bg--interactive-orange {
  background-color: $color--orange;
  color: #fff;
  h1, h2, h3, h4 {
    color: #fff;
  }
  a {
    color: #fff;
    text-decoration: underline;
    // border: 1px solid #fff;
    // padding: 5px 10px;
    // border-radius: 5px;
    // &:hover {
    //   background: rgba(255,255,255,0.3);
    // }
    &:hover {
      text-decoration: none;
    }
  }
}
.bg--interactive-teal {
  background-color: $color--teal;
  color: #fff;
  h1, h2, h3, h4 {
    color: #fff;
  }
  a {
    color: #fff;
    text-decoration: underline;
    // border: 1px solid #fff;
    // padding: 5px 10px;
    // border-radius: 5px;
    // &:hover {
    //   background: rgba(255,255,255,0.3);
    // }
    &:hover {
      text-decoration: none;
    }
  }
}
.bg--interactive-purple {
  background-color: $color--purple;
  color: #fff;
  h1, h2, h3, h4 {
    color: #fff;
  }
  a {
    color: #fff;
    text-decoration: underline;
    // border: 1px solid #fff;
    // padding: 5px 10px;
    // border-radius: 5px;
    // &:hover {
    //   background: rgba(255,255,255,0.3);
    // }
    &:hover {
      text-decoration: none;
    }
  }
}
.bg--interactive-red {
  background-color: $color--pink;
  color: #fff;
  h1, h2, h3, h4 {
    color: #fff;
  }

  a {
    color: #fff;
    text-decoration: underline;
    // border: 1px solid #fff;
    // padding: 5px 10px;
    // border-radius: 5px;
    // &:hover {
    //   background: rgba(255,255,255,0.3);
    // }
    &:hover {
      text-decoration: none;
    }
  }
}
.bg--interactive-yellow {
  background-color: $color--yellow;
  // a {
  //   color: #353a3f;
  //   border: 1px solid #353a3f;
  //   padding: 5px 10px;
  //   border-radius: 5px;
  //   &:hover {
  //     background: rgba(255,255,255,0.3);
  //   }
  // }
}
.bg--interactive-yellow {
  a {
    color: #353a3f;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}
.cke_videoDetector {
  display: block;
}
.collapse-item {
  .header-text {
    position: relative;
    background: $color--grey1--tint2;
    font-weight: $font--bold;
    padding: 10px 35px 10px 20px;
    cursor: pointer;
    margin-bottom: 20px;
    p {
      margin-bottom: 0;
    }
    &:focus {
      outline-color: $color--blue;
    }
    &:active {
      -webkit-tap-highlight-color: rgba(0,124,190,0.3);
    }
    &:after {
      font-family: "FontAwesome";
      font-size: 25px;
      opacity: 1;
      line-height: 25px;
      color: $color--black--tint1;
      display: block;
      content: "\f105";
      position: absolute;
      right: 20px;
      top: 10px;
    }
  }

  .content-text {
    padding: 0 20px;
    outline: none;
  }
  .content-text[aria-hidden="true"] {
    display: none;
  }
  .header-text[aria-expanded="true"] {
    background: $color--grey1--tint1;
  }
  .header-text[aria-expanded="true"]:after {
    content: "\f107";
  }
  // &.opened {
  //   .content-text {
  //     display: block;
  //   }
  //   .header-text {
  //     background: $color--grey1--tint1;
  //   }
  //   .header-text:after {
  //     content: "\f107";
  //   }
  // }

}
