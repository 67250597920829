@function end-row($span, $location, $columns) {
  @if $location == 'last' or $location == 'omega' {
    @return true;
  }
  @else {
    @if $location + ($span - 1) == column-count($columns) {
      @return true;
    }
    @else {
      @return false;
    }
  }
}

@function start-row($location) {
  @if $location == 'first' or $location == 'alpha' {
    @return true;
  }
  @else if $location == 1 {
    @return true;
  }
  @else {
    @return false;
  }
}