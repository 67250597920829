// File Cleaned Up
.ucsf-top-header {
  overflow: hidden;
  clear: both;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  &.logo .inside {
    height: 36px;
    line-height: 36px;
    ul.menu li.first{
      background: none;
      padding-left: 0;
      a {
        padding-left: 0;
      }
    }
  }
  .meganav-btn {
    display: none;
    @media (min-width: 769px) {
      display: block;
    }
    position: absolute;
    top: 15px;
    width: 14px;
    .meganav-menutxt {
      display: block;
      text-indent: -9999px;
      position: absolute;
      left: -9999px;
    }
    .meganav-icon-bar {
      display: block;
      background: $color--white;
      box-shadow: none;
      height: 1px;
      width: 14px;
      margin-bottom: 3px;
    }
  }
}
.ucsf-top-header .inside {
  position: relative;
  max-width: $width--large;
  margin: 0 auto;
  padding-left: 50px;
  padding-right: 50px;
  @include breakpoint(0 $width--tablet) {
    padding-left: 15px;
    padding-right: 15px;
  }
  height: 40px;
  line-height: 40px;
  font-weight: $font--normal;
  font-size: 14px;
  ul.menu {
    padding: 0;
    margin: 0;
    li.first {
      display: inline-block;
      float: left;
      padding-left: 0;
      a {
        margin-left: 0;
        @media (min-width: 769px) {
          margin-left: 22px;
        }
      }
    }
    li {
      display: none;
      float: right;
      padding: 0px 5px;
      @include breakpoint($bp--medium-up) {
        display: inline-block;
      }
      @include breakpoint($bp--960-up) {
        padding: 0px 10px;
      }
      @include breakpoint($bp--large-up) {
        padding: 0px 20px;
      }
      &.header-give {
        background: #007cbe;
        &:hover {
          background: $color--blue;
        }
      }
      a {
        display: block;
        color: $color--white;
      }
    }
  }
}

.ucsf-top-header.no-logo .inside {
  ul.menu {
    li.first {
      a {
        background: url("../img/ucsf-logo-banner.svg") no-repeat;
        background-size: 46px 22px;
        background-position: 0 42%;
        padding-left: 58px;
        margin-left: 0;
      }
    }
  }
}

.ucsf-top-header.no-logo .inside {
  .meganav-btn {
  & + ul.menu {
    li.first {
      a {
        @media (min-width: 769px) {
          margin-left: 22px;
          background: none;
          background-size: 0;
          background-position: 0;
          padding-left: 0;
        }
      }
    }
  }
  }
}
