.page-admin.logged-in div.tabs {
  margin-top: -60px;
  position: absolute;
  margin-left: auto;
  width: 1070px;
  max-width: 100%;
  padding-right: 50px;
  pointer-events: none;
  ul.primary {
    pointer-events: all;
    float: right;
    font-size: 14px;
    li {
      display: inline-block;
      a {
        display: block;
        color: $color--white;
        background-color: $color--link;
        border: 1px solid $color--link;
        padding: 2px 5px;
        border-radius: 2px;
        &:hover {
          text-decoration: none;
          color: $color--link;
          background-color: $color--white;
        }
      }
    }
  }
  ul.secondary {
    float: right;
    clear: right;
    margin-top: 2px;
    font-size: 12px;
    pointer-events: all;
    li {
      display: inline-block;
      a {
        display: block;
        border: 1px solid $color--link;
        padding: 2px 5px;
        border-radius: 2px;
        &:hover {
          text-decoration: none;
          color: $color--white;
          background-color: $color--link;
        }
      }
    }
  }
}


.logged-in.page-node div.tabs{
  position: fixed;
  top: 120px;
  left: 0px;
  padding: 0;
  width: auto;
  margin: 0;
  border: 1px solid #545454;
  border-left: 0;
  z-index: 100;
  border-radius: 0 2px 2px 0;
  ul.primary{
    background-color: rgba(255, 255, 255, 0.9);
    float: none;
    clear: none;
    li{
      display: block;
      a{
        color: #545454;
        display: block;
        padding: 2px 7px;
        &:hover {
          color: $color--white;
          background-color: #545454;
          text-decoration: none;
        }
      }
    }
  }
  ul.secondary{
    background-color: rgba(255, 255, 255, 0.9);
    font-size: 13px;
    float: none;
    clear: none;
    li{
      display: block;
      a{
        color: #545454;
        display: block;
        padding: 2px 7px;
        &:hover {
          color: $color--white;
          background-color: #545454;
          text-decoration: none;
        }
      }
    }
  }
}
