// #block-views-publications-block {
//   h2 {
//     border-bottom: 2px solid black;
//   }
//   .views-field-title {
//     margin-bottom: 15px;
//     font-size: 18px;
//   }
//   .views-row {
//     margin-bottom: 30px;
//     padding-bottom: 30px;
//     border-bottom: 2px dotted $color--grey2;
//   }
//   .field-name-field-publication-year {
//     color: $color--grey2;
//   }
// }

.node-ucsf-publication.node-teaser {

  & > h2,
  & > h4 {
    margin: 0;
    font-family: $main-font;
    font-weight: $font--normal;
    font-size: 18px;
    line-height: 1.3;
    padding-bottom: 4px;
  }
  .field-name-field-publication-authorlist {
    p {
      margin-bottom: 4px;
    }
  }
  .field-name-field-publication-name {
    font-size: 14px;
  }
}

.field-name-field-pg-ref-pubs > .field-items,
.field-name-field-person-publications-list > .field-items,
.field-name-field-person-pub-override-list > .field-items {
  & > .field-item {
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 2px dotted $color--grey2 !important;
    &:last-child {
      border-bottom: none !important;
      padding-bottom: 0;
    }
    &:first-child {
      padding-top: 10px;
    }
  }
}

.block-ucsf-b1gfoot-publication > .content {
  & > .node-teaser {
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 2px dotted $color--grey2 !important;
    &:last-child {
      border-bottom: none !important;
      padding-bottom: 0;
      margin-bottom: 0;
    }
    &:first-child {
      padding-top: 0px;
    }
  }
}


.sidebar #main .region-sidebar {
  .node-ucsf-publication  {
    & > h2 {
      font-size: 18px;
      margin: 0;
    }
    .field-item {
      margin-bottom: 0;
    }
  }
  .field-name-field-pg-ref-pubs > .field-items > .field-item {
    margin-bottom: 30px;
    &:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
    }
    &:first-child {
      padding-top: 0px;
    }
  }
}
