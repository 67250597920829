.nav-social {
  .fa {
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    color: #FFF;
    border-radius: 0%;
    font-size: 14px;
    background-color: #7B7979;
    margin-left: 20px
  }
  .fa-facebook,
  .fa-twitter,
  .fa-youtube {
    text-indent: 40px;
    &:before {
      text-indent: 0;
      float: left;
      width: 20px;
    }
  }
  .fa-facebook {
    &:hover {
      background-color:#3C599F;
      color: rgba(255, 255, 255, .8);
    }
  }
  .fa-twitter {
    &:hover {
      background-color:#32CCFE;
      color: rgba(255, 255, 255, .8);
    }
  }
  .fa-youtube {
    &:hover {
      background-color:#C52F30;
      color: rgba(255, 255, 255, .8);
    }
  }
}

#mobile-social-media {
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid #B4B9BF;
  .fa {
    text-indent: -9999px;
    width: 30px;
    height: 30px;
    margin-left: 25px;
    text-align: center;
    font-size: 22px;
    &:first-child {
      margin-left: 15px;
    }
    &:before {
      width: 30px;
      height: 30px;
      line-height: 30px;
    }
  }
}

/*----- Genral Classes start ------*/
/*Change icons circle size and color here*/

.article-meta-share {
  .fa {
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #FFF;
    color: rgba(255, 255, 255, 1);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    border-radius: 50%;
    font-size: 14px;
    background-color: #7B7979;
  }
  .fa-facebook {
    &:hover {
      background-color:#3C599F;
      color: rgba(255, 255, 255, .8);
    }
  }
  .fa-twitter {
    &:hover {
      background-color:#32CCFE;
      color: rgba(255, 255, 255, .8);
    }
  }
  .fa-youtube {
    &:hover {
      background-color:#C52F30;
      color: rgba(255, 255, 255, .8);
    }
  }
  .fa-linkedin {
    &:hover {
      background-color:#0085AE;
      color: rgba(255, 255, 255, .8);
    }
  }
  .fa-google-plus {
    &:hover {
      background-color:#CF3D2E;
      color: rgba(255, 255, 255, .8);
    }
  }
  .fa-envelope {
    &:hover {
      background-color: #fe9900;
      color: rgba(255, 255, 255, .8);
    }
  }
  .fa-print {
    &:hover {
      background-color:#00bf00;
      color: rgba(255, 255, 255, .8);
    }
  }
}


.share-buttons {
  float: right;
  margin-top: -4px;
  a, .st_sharethis_custom {
    margin-left: 10px;
    display: inline-block;
    width: 30px;
    border-bottom: 3px solid #7b7979;
    text-align: center;
    line-height: 1.5;
    font-size: 12px;
    color: #7b7979;
  }
  a.share-facebook:hover {
    border-color: #3b5998;
    color: #3b5998;
  }
  a.share-twitter:hover {
    border-color: #00abed;
    color: #00abed;
  }
  a.share-email:hover {
    border-color: #4b4b4b;
    color: #4b4b4b;
  }
  .st_sharethis_custom:hover {
    cursor: pointer;
    border-color: #00bf00;
    color: #00bf00;
  }
}
