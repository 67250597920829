#block-system-main {
  .field-item {
    position: relative;
    overflow: hidden;
  }
}

.wysiwyg_quote.align--right {
  margin-right: 11px;
  margin-left: 25px;
}
.wysiwyg_quote.align--left {
  margin-right: 36px;
  margin-left: 0;
}

.wysiwyg_quote {
  margin-bottom: 30px;
  padding: 30px;
  position: relative;
  background-color: $color--grey1--tint2;
  .field-item &{
    position: relative;
  }
  &:before {
    top: -11px;
     content: " ";
    // border-top: 1px solid $color--border;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -2;
    bottom: -20px;
    left: 12px;
    margin-top: 22px;
    // background-color: transparent;
  }
  &:after {
    bottom: -11px;
  }
  p {
    margin-bottom: 0;
    color: $color--black;
  }
  &.bg--navy:before {
    background-color: $color--navy;
  }
  &.bg--blue:before {
    background-color: $color--blue;
  }
  &.bg--teal:before {
    background-color: $color--teal;
  }
  &.bg--green:before {
    background-color: $color--green;
  }
  &.bg--orange:before {
    background-color: $color--orange;
  }
}
p.wysiwyg_quote--content {
  &:before {
    content: open-quote;
    position: absolute;
    margin-left: -19px;
    font-size: 35px;
    margin-top: -3px;
  }
  &:after {
    content: close-quote;
    font-size: 35px;
    height: 0;
    line-height: 0;
    vertical-align: middle;
    display: inline-block;
    padding-top: 10px;
  }
  font-family: $secondary-font;
  font-size: 25px;
  line-height: 1.1;
  margin-bottom: 10px;
}
.wysiwyg_quote--author {
  font-weight: bold;
  font-size: 15px;
  line-height: 1.5;
}

.wysiwyg_quote--org {
  font-size: 15px;
  line-height: 1.5;
}

@include breakpoint(0 $width--medium) {
  .wysiwyg_quote,
  .wysiwyg_quote.align--right,
  .wysiwyg_quote.align--left {
    margin-right: 15px;
    margin-left: 0;
  }
}
