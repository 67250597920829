
@font-face{
  font-family: "Granjon";
  src:url(../fonts/Granjon/GranjonLTStd.eot);
  src:url(../fonts/Granjon/GranjonLTStd.eot?#iefix) format("embedded-opentype"),
  url(../fonts/Granjon/GranjonLTStd.ttf) format("truetype"),
  url(../fonts/Granjon/GranjonLTStd.woff) format("woff");
  font-style:normal;
  font-weight:normal;
}

@font-face {
  font-family: 'HelveticaNeue-light';
  src: url('../fonts/HelveticaNeue/HelveticaNeueLight.eot');
  src: url('../fonts/HelveticaNeue/HelveticaNeueLight.eot?#iefix') format('embedded-opentype'),
  url('../fonts/HelveticaNeue/HelveticaNeueLight.woff2') format('woff2'),
  url('../fonts/HelveticaNeue/HelveticaNeueLight.woff') format('woff'),
  url('../fonts/HelveticaNeue/HelveticaNeueLight.ttf') format('truetype');
  font-style:normal;
  font-weight:normal;
}


* { -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;}


*:focus {
  outline-color: $color--blue;
}
html {
  overflow-y: scroll;
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
}

body {
  font-family: $main-font;
  font-size: $default--font-size;
  line-height: $default--line-height;
  font-weight: $font--normal;
  color: $color--black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
}

.region-content .paragraphs-item-textarea {
  margin-bottom: 30px;
}


h1 {
  font-weight: $font--extra--light;
  font-family: $main-font--light;
  font-size: 39px;
  line-height: 1.2;
  margin-bottom: 20px;
  letter-spacing: 0.5px;
  .font--header--granjon & {
    font-size: 41px;
    line-height: 1;
    font-weight: normal;
    font-family: $secondary-font;
    font-weight: $font--light;
    letter-spacing: normal;
  }
}


h2 {
  font-weight: $font--extra--light;
  font-family: $main-font--light;
  font-size: 34px;
  line-height: 1.2;
  margin-bottom: 18px;
  letter-spacing: 0.5px;
  .font--header--granjon & {
    font-weight: normal;
    font-size: 36px;
    line-height: 1;
    font-family: $secondary-font;
    font-weight: $font--light;
    letter-spacing: normal;
  }
}

.field-name-field-pg-blockref-blockref,
.field-name-field-pg-ref-pubs,
.field-name-field-pg-ref-profiles,
.field-name-field-person-publications-list,
.field-name-field-person-pub-override-list {
  .field-label {
    @extend h3;
  }
  h3 {
    margin-bottom: 30px;
    padding-bottom: 4px;
    .font--header--granjon & {
      padding-bottom: 0;
    }
  }
  .colon {
    display: none;
  }
}




* + h2 {
  margin-top: 28px;
}


h3 {
  font-weight: normal;
  font-size: 33px;
  line-height: 1.1;
  font-family: $secondary-font;
  margin-bottom: 14px;
  .font--header--granjon & {
    font-weight: $font--light;
    font-family: $main-font--header;
    font-size: 27px;
    line-height: 1.2;
  }
}
* + h3 {
  margin-top: 28px;
}


h4 {
  font-weight: normal;
  font-size: 28px;
  line-height: 1.1;
  font-family: $secondary-font;
  margin-bottom: 11px;
  .font--header--granjon & {
    font-weight: $font--light;
    font-family: $main-font--header;
    font-size: 22px;
    line-height: 1.2;
  }
}
* + h4 {
  margin-top: 28px;
}

h5 {
  font-weight: bold;
}

img {
  max-width: 100%;
  height: auto;
}
figure.image {
  line-height: 0;
  figcaption {
    font-size: 12px;
    line-height: 1.2;
    padding-top: 3px;
    margin-bottom: 10px;
    display: table-caption;
    caption-side: bottom;
  }
}

a,
a:active {
  -moz-transition: all .2s linear;
  -webkit-transition: all .2s linear;
  transition: all .2s linear;
  text-decoration: none;
  color: $color--link;
}

a:hover {
  color: $color--link--hover;
  text-decoration: none;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
a:hover img {
  opacity: 0.5;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

// implementing new link color

.region-content .field-name-field-body,
.region-content .field-name-body,
.region-content .paragraphs-items-field-paragraph-sections,
#sidebar .sidebar {
  a:not([class]),
  a.username {
    color: $color--link;
    background-color: transparent;
    text-decoration: none;
    transition: background-color 0.15s cubic-bezier(0.33,0.66,0.66,1);
    border-bottom: 1px solid $color--link;
    overflow-wrap: break-word;
    word-break: break-word;
    word-wrap: break-word;

    &:hover {
    	background-color: $color--blue--tint3;
    	border-bottom: 1px solid $color--blue--tint3;
    }
  }

  // exceptions

  .blog-author-thumbnail,
  .field-name-field-image,
  .ucsf-news-image,
  .no-underline,
  h2,
  h3,
  h4,
  h5 {
    a {
      border-bottom: none !important;

      &:hover {
        border-bottom: none !important;
        background-color: transparent !important;
      }
    }
  }

  // custom menus in sidebar
  .menu {
    a {
      &:hover {
        color: #052049;
        background-color: transparent !important;
      }
    }
  }

  .bg--interactive-blue,
  .bg--interactive-dark-blue,
  .bg--interactive-light-navy,
  .bg--interactive-dark-grey,
  .bg--interactive-teal,
  .bg--interactive-purple {
    a:not([class]) {
      color: $color--white;
      border-bottom: 1px solid $color--white;

      &:hover {
        color: #fff;
        border-bottom: none;
        background-color: transparent;
      }
    }
  }
}
// end new link color


#footer a {
  color: $color--white;
  &:hover {
    text-decoration: underline;
  }
}

em {
  font-style: italic;
}

strong, b {
  font-weight: 600;
}

p.small, small {
  font-size: 12px;
  line-height: 1.2;
}

hr {
  border: 0;
  border-bottom: 1px solid #E1E1E1;
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

.field-type-text-long,
.field-type-text-with-summary,
.cke_editable {
  ul li {
    position: relative;
    padding-left: 14px;
    margin-bottom: 8px;
    &:before  {
      content:"\2022";    /* escaped unicode character */
      line-height: 0;        /* kills huge line height on resized bullet */
      display: inline-block;  /* position bullet relative to list item */
      width: 14px;            /* position the bullet L- R relative to list item */
      margin-left: -14px;
    }
  }
  ol li {
    padding-left: 0;
    &:before {
      content: "";
    }
  }
  ul {
    margin-bottom: 20px;
    ul, ol {
      margin-bottom: 8px;
      margin-top: 8px;
    }
  }
  * + ul {
    margin-top: 20px;
  }
}



.field-type-text-long,
.field-type-text-with-summary,
.cke_editable {
  ol li {
    list-style-type: decimal;
    margin-left: 22px;
    margin-bottom: 8px;
  }
  ol {
    margin-bottom: 20px;
    margin-left: 15px;
    ol {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
  * + ol {
    margin-top: 20px;
  }
}

.field-name-body,
.field-type-text-long,
.field-type-text-with-summary,
.cke_editable {
  line-height: $default--line-height;
  overflow: auto;
  p {
    margin-bottom: 20px;
    &:last-child {
      //margin-bottom: 0;
    }
  }
}

input {
  font-family: $main-font;
}

// table {
//   font-size: 16px;
//   line-height: 1.2;
//   margin-bottom: 30px;
//   width: 100%;
//   thead {
//     background-color: $color--grey1--tint2;
//   }
//   td, th {
//     padding: 12px;
//     border-left: 1px solid $color--border;
//   }
//   th {
//     text-transform: uppercase;
//     font-weight: $font--bold;
//     font-size: 14px;
//     color: #8a8a8a;
//     &:first-child {
//       border-left: 0;
//     }
//     img {
//       width: auto;
//     }
//   }
//   tbody tr {
//     border-bottom: 1px solid $color--border;
//     td:first-child {
//       border-left: 0;
//     }
//   }
//   tr.even, tr.odd {
//     background-color: transparent;
//     border-bottom: 1px solid $color--border;
//   }
//   td.active {
//     background-color: $color--grey1--tint2;
//   }
// }

// * + table {
//   margin-top: 30px;
// }

.row-full-width {
  .inside {
    max-width: $width--large;
    margin: 0 auto;
    padding-left: 50px;
    padding-right: 50px;
    @include breakpoint(0 $width--tablet) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}


input[type=text],
input[type=password],
textarea {
  border-radius: 2px;
  border: 1px solid $color--border;
  &:hover {
    border: 1px solid $color--border--hover;
  }
  &:focus {
    outline: none;
    border: 1px solid $color--border--active;
  }
}
input[type=text],
input[type=password] {
  padding: 3px 8px 3px 8px;
}
textarea, .form-textarea-wrapper textarea {
  padding: 5px 8px;
  margin: 3px 0;
}
input:focus, textarea:focus {
  box-shadow: 0 0 10px #0071AD;
  //padding: 3px 0px 3px 3px;
  //margin: 5px 1px 3px 0px;
  border: 1px solid #0071AD;
}

input[type=submit] {
  border: 0;
  border-radius: 2px;
  @include fix-white-font();
  font-size: 16px;
  padding: 3px 15px;
  margin-right: 8px;
  background-color: $color--grey1--tint1;
  &:hover {
    background-color: lighten($color--grey1--tint1, 5%);
  }
  &[id*=submit] {
    background-color: $color--blue;
    color: $color--white;
    &:hover {
      background-color: lighten($color--blue, 5%);
    }
  }
  &[id*=preview] {
    background-color: $color--black--tint1;
    color: $color--white;
    &:hover {
      background-color: lighten($color--black--tint1, 5%);
    }
  }
  &[id*=delete] {
    background-color: #990000;
    color: $color--white;
    &:hover {
      background-color: lighten(#990000, 5%);
    }
  }
  &:hover {
    cursor: pointer;
  }
}

textarea {

}

fieldset {
  border: 1px solid $color--border;
  padding: 2.5em 0 0 0;
  position: relative;
  margin: 1em 0;
  .fieldset-legend {
    margin-top: 0.5em;
    padding-left: 15px;
    position: absolute;
    text-transform: uppercase;
    font-weight: bold;
  }
  .fieldset-wrapper {
    padding: 0 13px 13px 15px;
  }
}


.align--center {
  margin-left: auto;
  margin-right: auto;
}
.align--left {
  @include breakpoint($bp--medium-up) {
    float: left;
    margin-right: 3%;
  }
}
.align--right {
  @include breakpoint($bp--medium-up) {
    float: right;
    margin-left: 3%;
  }
}

.size--full {
  width: 100%;
  float: none;
  margin-left: 0;
  margin-right: 0;
}
.size--half{
  @include breakpoint($bp--medium-up) {
    width: 50%;
  }
}
.size--twofifth {
  @include breakpoint($bp--medium-up) {
    width: 40%;
  }
}
.size--third {
  @include breakpoint($bp--medium-up) {
    width: 33%;
  }
}
.responsive-video {
  position: relative;
  padding-bottom: 56%;
  margin-bottom: 20px;
  &.size--half{
    @include breakpoint($bp--medium-up) {
      padding-bottom: 28%;
    }
  }
  &.size--twofifth {
    @include breakpoint($bp--medium-up) {
      padding-bottom: 22%;
    }
  }
  &.size--third {
    @include breakpoint($bp--medium-up) {
      padding-bottom: 18%;
    }
  }
}

.cke_editable .responsive-video{
  padding-bottom: 0;
  &.size--half,
  &.size--twofifth,
  &.size--third {
    @include breakpoint($bp--medium-up) {
      padding-bottom: 0;
    }
  }
  img {
    width: 100%;
  }
}

.bg--white {
  background-color: $color--white;
}
.bg--grey {
  background-color: $color--grey2--tint1;
}
.bg--blue {
  background-color: $color--blue--tint3;
}
.bg--teal {
  background-color: $color--teal--tint3;
}
.bg--green {
  background-color: $color--green--tint3;
}
.bg--orange {
  background-color: $color--orange--tint3;
}

.field-label-inline .field-label,
.field-label-inline .field-items {
  float:left;
}
.field-label-inline {
  overflow: hidden;
}

.placeholder--block {
  display: none;
}

#main .field-name-field-paragraph-sections {
  & > .field-items > .field-item {
    margin-top: 40px;
  }
}
.text-indent {
  display: inline-block;
  text-indent: -99999px;
}
