@function fixed-gutter($user-columns, $user-gutter, $user-gutter-style) {
  @if index($user-gutter-style, 'fixed') {
    // @debug 'Fixed in output style';
    @return true;
  }
  @else if type-of($user-columns) == 'number' and not unitless($user-gutter) {
    // @debug 'Equal columns with a unit based gutter';
    @return true;
  }
  @else if type-of($user-columns) == 'list' {
    @if unit(nth($user-columns, 1)) != unit($user-gutter) {
      // @debug 'Columns and gutters are different units';
      @return true;
    }
  }
  @else {
    @return false;
  }

  @return false;
}

@function gutter-property($user-columns, $user-gutter, $user-gutter-style) {
  @if fixed-gutter($user-columns, $user-gutter, $user-gutter-style) {
    @return 'padding';
  }
  @else {
    @return 'margin';
  }
}