.page-node-edit,
.page-node-add {

  div[id*=paragraph-bundle-title] {
    display: none;
  }
  // Ugly short-term fix
  #field-paragraph-sliders-add-more-wrapper {
    // label, p {
    //   display: none;
    // }
  }
  #field-paragraph-sidebar-add-more-wrapper > .form-item, .form-item-field-paragraph-sidebar-add-more-type {
    & > label, & > p {
      display: none;
    }
  }
  #field-paragraph-sections-add-more-wrapper > .form-item, .form-item-field-paragraph-sections-add-more-type {
    & > p {
      display: none;
    }
  }


  .field-name-field-paragraph-sliders {
    .form-wrapper {
      margin-bottom: 10px;
    }
    div[id*="paragraph-bundle-preview"] {
      min-height: 90px;
    }
  }
  .field-multiple-table {
    th {
      padding: 0;
      label {
        margin-left: 15px;
        font-family: $main-font;
        font-size: 14px;
        line-height: 1.1;
        padding: 12px 12px 12px 0;
        font-weight: 500;
      }
    }
  }

  .tabledrag-toggle-weight-wrapper,
  .ckeditor_links,
  .filter-help,
  .filter-help ~ .form-type-select {
    display: none !important;
  }

  // .field-name-field-paragraph-sections {
  //   .form-actions {
  //     display: none;
  //     position: absolute;
  //     right: 0;
  //     top: 0;
  //     text-align: right;
  //     input[id*=actions-edit-button],
  //     input[id*=actions-remove-button],
  //     input[id*=actions-collapse-button] {
  //       font-family: $font-awesome;
  //       padding: 3px 6px;
  //     }
  //   }
  // }


  // Section below is not used for now due to new designs/wireframes
  .field-name-field-paragraphs {
    .tabledrag-toggle-weight-wrapper {
      display: none;
    }
    margin-left: -20px;
    margin-right: -8px;
    table.sticky-header {
      z-index: 5;
    }
    .field-multiple-drag {
      padding: 0;
      width: 5px !important;
      position: relative;
      border: 0;
      a.tabledrag-handle {
        padding: 0 !important;
        margin: 0;
        float: none;
        display: block;
        min-height: 20px;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: $color--blue;
        .handle {
          display: none;
        }
      }
    }
    .field-name-field-textarea-body label {
      display: none;
    }
    div[id*=paragraph-bundle-title] {
      background-color: $color--blue;
      color: $color--white;
      margin-left: -16px;
      display: inline-block;
      padding: 2px 5px 2px 17px;
      margin-bottom: 10px;
      @include fix-white-font();
    }
    thead {
      background-color: transparent;
      display: none;
    }
    th {
      color: $color--black--tint1;
      label {
        padding-left: 20px;
      }
    }
    td {
      padding: 0 0 0 10px;
      position: relative;
      &:hover {
        .form-actions {
          display: block;
        }
      }
    }
    // .form-actions {
    //   display: none;
    //   position: absolute;
    //   right: 0;
    //   top: 0;
    //   text-align: right;
    //   input[id*=actions-edit-button],
    //   input[id*=actions-remove-button],
    //   input[id*=actions-collapse-button] {
    //     font-family: $font-awesome;
    //     padding: 3px 6px;
    //   }
    // }
  }

  .field-group-fieldset, .field-group-fieldset.collapsed {
    border: 1px solid #B4B9BF;
    background-color: #F5F5F5;
    &:hover {
      cursor: pointer;
    }
    .fieldset-title {
      text-transform: none;
      font-weight: normal;
      font-size: 18px;
      color: #000;
      padding-left: 20px;
    }
    .fieldset-wrapper {
      background-color: #FFF;
      overflow: hidden;
      &:hover {
        cursor: default;
      }
    }
    .fieldset-legend {
      background-position: 12px 65% !important;
    }
  }
  .field-group-fieldset.collapsed .fieldset-legend {
    background-position: 12px 50%;
  }
  .group-content-area.field-group-fieldset {
    .fieldset-wrapper {
      padding: 0;
    }
    .form-type-textarea {
      margin: 0;
      label {
        display: none;
      }
      .form-textarea-wrapper {
        margin: 0 -1px -1px -1px;
      }
    }
  }
}
div[id^=edit-field-paragraphs-und-add-more] {
  text-align: center;
  padding: 20px 0;
}

input[id*=remove-button],
input[id*=collapse-button],
input[id*=edit-button] {
  font-family: $font-awesome;
  padding: 3px 6px;
}

.field-name-field-paragraph-sections,
.field-name-field-paragraph-sidebar {
  div[id*=field-paragraph-sections-und-add-more] {
    margin-bottom: 50px;
  }
  input[id*=add-more-bundle-blockreference],
  input[id*=add-more-bundle-textarea],
  input[id*=add-more-bundle-profilereferences],
  input[id*=add-more-bundle-publicationsreferences] {
    // font-family: $font-awesome;
    padding: 5px 25px;
    text-align: center;
    min-width: 180px;
    //font-size: 60px;
  }
  .form-type-textarea {
    label {
      visibility: hidden;
    }
  }
}
a.tabledrag-handle .handle {
  width: 20px;
  height: 22px;
}

.field-multiple-drag ~ td > .ajax-new-content > .form-actions,
.field-multiple-drag ~ td > .form-actions {
  margin: -28px 0 0 0;
  position: absolute;
  & > p {
    display: none;
  }
  & > div.ajax-progress-throbber {
    display: none;
  }
  & > input[id*=remove-button],
  & > input[id*=collapse-button],
  & > input[id*=edit-button] {
    margin-left: -47px;
    width: 28px;
    height: 28px;
    display: block;
    position: absolute;
  }
  & > input[id*=collapse-button],
  & > input[id*=edit-button] {
    margin-top: -35px;
  }
}

