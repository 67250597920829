.page-node-add {
  #breadcrumb {
    display: none;
  }
}

.node-type-list {
  .node-type-item {
    @include gallery(2, 5%, 2%);
    p {
      color: $color--text;

    }
    a {
      display: block;
      background-color: $color--grey1--tint2;
      padding: 3% 5% 3% 3%;
      border-radius: 5px;
      &:hover {
        text-decoration: none;
        background-color: $color--grey2--tint3;
      }
    }
  }
}
