.item-list {
  overflow: hidden;
  ul.pager {
    margin-top: 100px;
    padding-bottom: 100px;
    display: table;
    margin: auto;
    .pager-first {
        display: none;
      }
    .pager-last {
      display: none;
    }
    li {
      float: left;
      list-style: none;
      text-align: center;
      list-style: none;
      margin-left: 0;
      font-weight: bold;
      a {
        padding: 10px 16px;
        border-bottom: 2px solid $color--grey1;
        text-transform: capitalize;
        color: #000;
        font-weight: bold;
        &:hover {
          border-color: $color--blue;
          text-decoration: none;
        }
      }
    }
    li.pager-current {
      padding: 9px 16px;
      background-color: $color--blue;
      color: #FFF;
    }
  }
}
