.region-sidebar .block-menu-block,
.region-sidebar-top .block-menu-block,
#sidebar .sidebar .region-sidebar .block-menu-block,
#sidebar .sidebar .region-sidebar-top .block-menu-block {

  padding-bottom: 0px;
  margin: 0 0 40px 0;

  & > h2,
  & > h3 {
    margin: 0;
    font-size: 26px;
    line-height: 1.2;
    font-family: $main-font;
    a {
      display: block;
      color: $color--black;
      padding: 12px 30px;
      border-bottom: 1px solid #B4B9BF;
      font-weight: 300;
    }
  }
  .content a {
    display: block;
    padding: 14px 30px;
    border-bottom: 1px solid #B4B9BF;
    font-size: 18px;
  }
  ul,ol {
  	&.menu {
  		font-size: 18px;
  	}
  }
  .active {
    & > a {
      color: #4b4b4b;
      position: relative;
      &:before {
        font-family: $font-awesome;
        content: "\f0da";
        color: #000;
        display: block;
        position: absolute;
        margin-left: -17px;
        margin-top: 1px;
      }
    }
  }
  .menu-block-wrapper > ul > li > ul > li > a {
    padding: 12px 30px 12px 45px;
  }
  .menu-block-wrapper > ul > li > ul > li > ul > li > a {
    padding: 12px 30px 12px 60px;
  }

}

.region-sidebar .block-menu-block {
	 background-color: inherit;
}
.region-sidebar-top .block-menu-block {
	 background-color: #EDEDED;
     box-shadow: 0px 0px 4px 1px #E3E3E3;
     -webkit-box-shadow: 0px 0px 4px 1px #E3E3E3;
     -moz-box-shadow: 0px 0px 4px 1px #E3E3E3;
     -o-box-shadow: 0px 0px 4px 1px #E3E3E3;
     h2 {
       margin-bottom: 0 !important;
     }
}
