.block-ucsf-b1gfoot-xmlnews,
.view-display-id-news_block {
  position: relative;
  .content {
    position: relative;
  }
  .more-link {
    top: -63px;
  }
  .ucsf-news {
    overflow: hidden;
    // margin-bottom: 50px;
    @include breakpoint($bp--medium-up) {
      margin-bottom: 20px;
      @include gallery(3, 8%, 0%);
    }

    &:last-child,
    &:nth-child(3) {
      margin-bottom: 0;
    }

    &:nth-child(4) {
      display: none;
    }

    .ucsf-news-image {
      float: left;
      width: 112px;
      overflow: hidden;
      margin-right: 25px;
      margin-bottom: 13px;
      display: block;
    }
  }
  .content h3,
  .view-content h3 {
    margin-top: 0;
    font-size: 18px;
    line-height: 1.3;
    overflow: hidden;
    margin: 0;
    font-family: $main-font;
    font-weight: 400;
    margin-bottom: 12px;
    @include breakpoint($bp--medium-up) {
      margin-bottom: 0;
    }
    a {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
  .date {
    font-weight: bold;
    font-size: 12px;
    color: $color--dark-grey;
    margin-bottom: 5px;
  }
}

.region-content {
  .block-ucsf-b1gfoot-xmlnews,
  .view-display-id-news_block {
    h3 a {
      color: $color--black;
      &:hover {
        color: $color--blue;
      }
    }
  }
}

// Sidebar styles
body.sidebar {
  .block-ucsf-b1gfoot-xmlnews .ucsf-news,
  .view-display-id-news_block .ucsf-news {
    @include breakpoint($bp--medium-up) {
      @include gallery(2, 8%, 3%);
    }
    &:last-child {
      margin-bottom: 0;
    }
    &:nth-child(4) {
      display: block;
    }
  }
}

body.sidebar .region-sidebar .block-ucsf-b1gfoot-xmlnews .ucsf-news,
.field-name-field-paragraph-sidebar .block-ucsf-b1gfoot-xmlnews .ucsf-news,
.field-name-field-blockref-sidebar .block-ucsf-b1gfoot-xmlnews .ucsf-news,
body.sidebar .region-sidebar .view-display-id-news_block .ucsf-news,
.field-name-field-paragraph-sidebar .view-display-id-news_block .ucsf-news,
.field-name-field-blockref-sidebar .view-display-id-news_block .ucsf-news {
  @include breakpoint($bp--medium-up) {
    @include gallery(1, 0);
    &:nth-child(n) {
      float: none;
    }
  }
  &:last-child,
  &:nth-child(3) {
    margin-bottom: 0;
  }
  &:nth-child(4) {
    display: none;
  }
  overflow: hidden;
  border-top: 1px solid $color--grey2;
  padding-top: 28px;
  margin-top: 28px;
  &:first-child {
    border-top: none;
    padding-top: 0;
    margin-top: 0;
  }
  .ucsf-news-image {
    width: 65px;
    margin-right: 20px;
  }
}





.view-display-id-news_page {
  .ucsf-news {
    clear: both;
    overflow: hidden;
    border-bottom: 1px solid $color--grey2;
    padding-bottom: 50px;
    margin-bottom: 50px;
    .ucsf-news-image {
      margin-bottom: 10px;
      @include breakpoint($bp--small-up) {
        float: left;
        margin-right: 40px;
        margin-bottom: 0;
      }
      img {
        width: 170px;
      }
    }
    .date {
      color: #808285;
      margin-bottom: 8px;
      font-size: 15px;
    }
    h3 {
      font-size: 25px;
      margin-bottom: 5px;
      margin-top: 0;
      font-family: $main-font;
      a {
        color: #000;
        font-weight: 300;
        &:hover {
          color: $color--blue;
          text-decoration: none;
        }
      }
    }
  }
}

.node-type-news {
  #page-title {
    margin-bottom: 10px;
  }
  .field-name-field-text-byline {
    border-bottom: 1px solid $color--grey2;
    margin-bottom: 25px;
    padding-bottom: 5px;
    margin-top: 5px;
  }
}

#main #content div[id*=block-views-news-news-block] > h3,
#main #content div[id*=block-ucsf-b1gfoot-xmlnews-startingpoint-xmlnews] > h3 {
  padding-right: 120px;
}

.news-short-teaser {
  margin-top: 30px;

  .news-short-teaser__image{
    float: left;
    margin-right: 30px;
    overflow: hidden;
    margin-bottom: 13px;
  }
  &.news-short-teaser__sidebar {
    .news-short-teaser__image{
      margin-right: 20px;
    }
  }
  .news-short-teaser__detail {
    p {
      margin-bottom: 5px;
    }
    time {
      font-weight: bold;
      font-size: $font-size-small;
      margin-bottom: 5px;
      color: $font-grey;
    }
    h3 {
      a {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
      font-weight: 100;
      //font-size: $font-size-large;
      margin: 0;
      overflow: hidden;
    }
  }
}
.latest-news-list {
  .latest-news-list__heading {
    margin-top: 10px;
    border-bottom: 2px solid $color--teal;
    margin-bottom: 30px;
    padding-bottom: 4px;
    h3 {
      margin-bottom: 0;
    }
    .more-link {
      top: 5px;
    }
  }
  .latest-news-list--item {
    float: left;
    display: inline-block;
    &.views-row-odd {
      clear:both;
      margin-right: 8%;
    }
    @include breakpoint($bp--medium-up) {
      width: 46%;
    }
    .latest-news-list--title {
      margin-top: 10px;
      font-size: 18px;
    }
    .news-short-teaser {
      margin-top:0;
    }
  }
}
.news-teaser {
  border-bottom: 1px solid $color--grey2;
  margin-bottom: 50px;
  padding-bottom: 50px;
  width: 100%;
  display: table;
  .news-teaser__image {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 20px;
    @include breakpoint($bp--small-up) {
      float: left;
      margin-right: 40px;
    }
    display: table;
    img {
      width: 100%;
      @include breakpoint($bp--small-up) {
        width: 170px;
      }
    }
  }
  .news-teaser__detail {
    display: table;
    h3 {
      margin-bottom: 5px;
      margin-top: 0;
      font-weight: 300;
      font-size: 25px;
    }
    time {
      line-height: 1.5rem;
    }
    .news-teaser__author-date {
      margin-bottom: 8px;
      font-size: $font-size-small;
      color: $font-grey;
    }
  }
}
