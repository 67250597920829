div.messages {
  border-color: $color--border;
  background-color: $color--grey2--tint3;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  background-position: 18px;
  &:before {
    content: "";
    display: block;
    width: 8px;
    height: auto;
    top: -1px;
    left: -1px;
    bottom: -1px;
    position: absolute;
  }

  &.status {
    border-color: $color--border;
    color: #383631;
    background-image: url(../img/icon-status.png);
    &:before {
      background-color: #009933;
    }
  }
  &.warning {
    border-color: $color--border;
    color: #383631;
    background-image: url(../img/icon-warning.png);
    &:before {
      background-color: #FA5D00;
    }
  }
  &.error {
    border-color: $color--border;
    color: #383631;
    background-image: url(../img/icon-error.png);
    &:before {
      background-color: #D20000;
    }
  }
}

a.block-demo-backlink {
  top: 35px;
}
