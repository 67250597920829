body.sidebar {
  #main {
    .sidebar {
      margin-bottom: 50px;
      border-top: 15px solid;
      border-bottom: 15px solid;
      padding: 30px 30px 0 30px;
      box-shadow: 0px 0px 4px 1px #E3E3E3;
     -webkit-box-shadow: 0px 0px 4px 1px #E3E3E3;
     -moz-box-shadow: 0px 0px 4px 1px #E3E3E3;
     -o-box-shadow: 0px 0px 4px 1px #E3E3E3;
      ol, ul {
	    list-style: disc inside;
	    &.menu {
	    	list-style: none;
	    }
	  }
      .block {
        @extend %clearfix;
      }
      .paragraphs-item-blockreference {
        .block {
          @include breakpoint(0 $width--medium) {
            h2:after {
              font-family: "fontAwesome";
              content: "\f067";
              color: #000;
              font-size: 25px;
              right: 50px;
              position: absolute;
            }
            .content {
              display: none;
            }
          }
        }
      }
      .block {
        margin-bottom: 20px;
        &:last-child {
          padding-bottom: 0px;
          margin-bottom: 0;
        
			&.block-menu-block {
				
			} 
        }
      }
      .field-name-field-paragraph-sidebar > .field-items > .field-item,
      .field-name-field-blockref-sidebar > .field-items > .field-item,
      .field-name-field-pg-blockref-blockref > .field-items > .field-item,
      .region-sidebar > .block {
      	margin: 0 0 30px 0;
        &:after {
          content: "";
          display: block;
          height: 5px;
          margin-left: -10px;
          margin-right: -10px;
          margin-top: 50px;
          margin-bottom: 50px;
        }
        
        &:last-child:after {
          content: none;
        }
      }
      .region-sidebar > .block-block .content {
        overflow: auto;
      }
    }
  }
}
