/* Ckeditor Styling */

body.ucsf-b1gfoot {
  .cke_reset {
    &, * {
      border-radius: 0;
      text-shadow: none;
      box-shadow: none;
    }
    .cke_top {
      background: $color--white;
    }
    .cke_combo_button {
      background: $color--white;
      border: 1px solid $color--border;
    }
    .cke_combo_off a.cke_combo_button:hover {
      background: #fff;
      border: 1px solid #0074BD;
    }
     .cke_combo_off a.cke_combo_button:active,
     .cke_combo_on a.cke_combo_button {
      border: 1px solid #0074BD;
      border-bottom-width: 3px;
      background: $color--white;
      box-shadow: none;
    }
    .cke_button_on {
      background: #fff;
      box-shadow: inset 0px -3px 0px $color--blue;
      -moz-border-radius: 0;
      -webkit-border-radius: 0;
      border-radius: 0;
    }

    .cke_button_on:hover {
      box-shadow: inset 0px -3px 0px $color--blue;
    }

    a.cke_button_on:hover,
    a.cke_button_on:focus,
    a.cke_button_on:active,
    .cke_button:first-child,
    .cke_button:last-child {
      -moz-border-radius: 0;
      -webkit-border-radius: 0;
      border-radius: 0;
    }
    a.cke_button_off:hover,
    a.cke_button_off:focus,
    a.cke_button_off:active,
    a.cke_button_disabled:hover,
    a.cke_button_disabled:focus,
    a.cke_button_disabled:active {
      background: #eee;
      box-shadow: none;
      border-color: #0074BD;
      -moz-border-radius: 0;
      -webkit-border-radius: 0;
      border-radius: 0;
    }
    .cke_chrome {
      box-shadow: none;
    }
    .cke_toolgroup {
      background: $color--white;
      border: 1px solid $color--border;
    }
    .cke_bottom {
      background: $color--white;
      a {
        color: $color--black;
      }
    }

    .ckeditor_links {
      border-left: 1px solid #DDDDDD;
      border-right: 1px solid #DDDDDD;
      display: block;
      padding: 10px;
      width: auto;
      &:hover {
        background: #eee;
        text-decoration: none;
      }
    }
  }
  .cke_reset_all {
    label {
      display: inline-block;
      margin-top: 5px;
      padding-bottom: 2px;
      position: relative;
    }
    .cke_dialog_body label.cke_required {
      font-weight: normal;
    }
    .cke_dialog_body {
      border: 1px solid $color--border;
      background-color: $color--white;
    }
    fieldset {
      border: 1px solid $color--border;
      border-top: 3px solid $color--blue;
      padding: 10px;
    }
    .cke_combopanel {
      height: 170px;
      width: 170px;
      border: 1px solid #DDDDDD;
      margin-top: 3px;
    }
    .cke_dialog_footer {
      background: $color--grey2--tint3;
    }
    a.cke_dialog_tab:hover {
      text-decoration: none;
      background: #fff;
      color: #0074BD;
    }
    select.cke_dialog_ui_input_select {
      box-shadow: none;
    }
    input.cke_dialog_ui_input_text,
    input.cke_dialog_ui_input_password,
    textarea.cke_dialog_ui_input_textarea {
      box-shadow: none;
      border-color: $color--border;
    }
    .cke_dialog_title {
      font-family: $secondary-font;
      border-bottom: none;
      border-radius: 0;
      box-shadow: none;
      color: $color--white;
      font-size: 18px;
      text-shadow: none;
      @include fix-white-font();
    }
    .cke_dialog_ui_vbox_child label {
      top: -2px;
    }
    input.cke_dialog_ui_input_text:focus,
    input.cke_dialog_ui_input_password:focus,
    textarea.cke_dialog_ui_input_textarea:focus,
    select.cke_dialog_ui_input_select:focus {
      border-color: #0074BD;
    }
    a.cke_dialog_ui_button:focus,
    a.cke_dialog_ui_button:hover,
    a.cke_dialog_ui_button:active {
      border-color: #0074BD;
      box-shadow: none;
      text-decoration: none;
      color: $color--white;
      background: #0074BD;
    }
    a.cke_dialog_ui_button {
      background: $color--grey2--tint3;
      padding: 4px 0;
      border-radius: 2px;
      span {
        font-weight: normal;
      }
    }
    a.cke_dialog_ui_button:focus span,
    a.cke_dialog_ui_button:hover span,
    a.cke_dialog_ui_button:active span {
      color: #fff;
      text-shadow: none;
      // @include fix-white-font();
    }
    .cke_ltr .cke_dialog_close_button {
      background: #B73939;
      right: 10px;
      top: 10px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      &:hover {
        background-color: #e55454;
      }
    }
    a.cke_dialog_ui_button_ok {
      background: $color--link;
      border-color: $color--link;
      box-shadow: none;
      &:hover {
        background: lighten($color--link, 5%);
        border-color: lighten($color--link, 5%);
      }
    }
    a.cke_path_item:hover,
    a.cke_path_item:focus,
    a.cke_path_item:active {
      border-radius: 0;
      -moz-border-radius: 0;
      -webkit-border-radius: 0;
      box-shadow: none;
      text-decoration:none;
      text-shadow: none;
    }
  }
  .cke_contents {
    padding: 5px 8px;
  }
  .cke_dialog_tabs {
    a.cke_dialog_tab {
      border-radius: 0;
      border: 1px solid transparent;
      margin-right: 0;
      text-transform: uppercase;
      color: #BFBFBF;
      background: transparent;
      top: -1px;
      &:hover {
        border-color: transparent;
        color: #444;
        background: transparent;
      }
    }
    a.cke_dialog_tab_selected {
      border: 1px $color--border solid;
      border-bottom: 1px solid $color--white;
      color: #444;
      background: transparent;
      &:hover {
        border: 1px $color--border solid;
        border-bottom: 1px solid $color--white;
      }
    }
  }
}
.cke_editable {
  .cke_widget_wrapper:hover > .cke_widget_element,
  .cke_widget_wrapper:hover .cke_widget_editable {
    outline: 2px solid $color--grey1--tint2 !important;
  }
}
