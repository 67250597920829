// table {
//   width: 100%;
//   border-collapse: collapse;
//   border-spacing: 0;
//   caption {
//     font-style: italic;
//   }
//   td, th {
//     padding: 12px;
//     text-align: left;
//   }
//   th {
//     font-weight: 600;
//   }
// }
// REMOVING UN-COMPLIANT TABLE AND REPLACING WITH PL PATTERN
// table {
//   font-size: 16px;
//   line-height: 1.2;
//   margin-bottom: 30px;
//   width: 100%;
//   thead {
//     background-color: $color--grey1--tint2;
//   }
//   td, th {
//     padding: 12px;
//     border-left: 1px solid $color--border;
//   }
//   th {
//     text-transform: uppercase;
//     font-weight: $font--bold;
//     font-size: 14px;
//     color: #8a8a8a;
//     &:first-child {
//       border-left: 0;
//     }
//     img {
//       width: auto;
//     }
//   }
//   tbody tr {
//     border-bottom: 1px solid $color--border;
//     td:first-child {
//       border-left: 0;
//     }
//   }
//   tr.even, tr.odd {
//     background-color: transparent;
//     border-bottom: 1px solid $color--border;
//   }
//   td.active {
//     background-color: $color--grey1--tint2;
//   }
// }

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 1em 0 1em 0;
  caption {
    font-size: 1.4em;
    text-align: left;
  }
  td, th {
    padding: 12px;
    text-align: left;
  }
  td {
    border: 1px $color--grey2 solid;
    &.active {
      background: $color--yellow--tint3;
    }
  }
  th {
    background: $color--blue;
    color: $color--white;
    border-color: $color--blue;
    font-weight: 500;
    font-size: 1.1em;
    a {
      color: $color--white;
    }
  }
  tr.even, tr.odd {
    background: $color--white;
  }
  tr:nth-child(even) {
    background: $color--grey3;
  }
  tr {
    &:hover {
      background: $color--yellow--tint3;
    }
  }
}

.table--bordered {
  thead {
    th {
      background: $color--blue;
      color: $color--white;
      border: 1px solid $color--blue;
    }
  }
  tbody {
    td {
      border: 1px $color--border solid;
    }
  }
  tfoot {
    th {
      border: 1px $color--blue solid;
    }
  }
}

.table--condensed {
  td, th {
    border-bottom: 1px $color--border solid;
  }
}

.table--zebra  {
  tr:nth-child(even) {
    background: $color--grey3;
  }
  tr {
    &:hover {
      background: $color--yellow--tint3;
    }
  }
}
