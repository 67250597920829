//////////////////////////////
// Find Opposite Direction
//////////////////////////////
@function opposite-direction($dir) {
  @if $dir == 'left' {
    @return right;
  }
  @else if  $dir == 'right' {
    @return left;
  }
  @else if $dir == 'ltr' {
    @return rtl;
  }
  @else if $dir == 'rtl' {
    @return ltr;
  }
  @else if $dir == 'top' {
    @return bottom;
  }
  @else if $dir == 'bottom' {
    @return top;
  }
  @else {
    @warn "#{$dir} is not a direction! Make sure your direction is all lowercase!";
    @return false;
  }
}

//////////////////////////////
// Find Direction Name
//////////////////////////////
@function named-direction($dir) {
  @if $dir == 'ltr' {
    @return left;
  }
  @else if $dir == 'rtl' {
    @return right;
  }
  @else {
    @warn "#{$dir} is not a valid HTML direction! Make sure you are using a valid HTML direction";
    @return false;
  }
}