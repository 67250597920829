
%full-width {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
}

%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin gallery($numPerRow, $margin, $marginbottom: $margin) {
  width: ((100% - (($numPerRow - 1) * $margin)) / $numPerRow);
  &:nth-child(n) {
    margin-bottom: $marginbottom;
    margin-right: $margin;
    float: left;
    clear: none;
  }
  &:nth-child(#{$numPerRow}n) {
    margin-right: 0;
  }
  &:nth-child(#{$numPerRow}n+1) {
    clear: both;
  }
}

@mixin fix-white-font() {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
}

@mixin border-radius($radius: 5px, $moz: true, $webkit: true, $ms: true) {
  @if $moz    { -moz-border-radius:    $radius; }
  @if $webkit { -webkit-border-radius: $radius; }
  @if $ms     { -ms-border-radius:     $radius; }
  border-radius: $radius;
}

// Use for blue links that change to navy on hover
@mixin link-default() {
  & {
    color: $color--link;

    &:hover {
      color: $color--link-dark;
    }
  }
}


// Use for navy color links that change to blue on hover
@mixin link-dark-to-light() {
  & {
    color: $color--link-dark;

    &:hover {
      color: $color--link-dark--hover;
    }
  }
}

// Requirement: starting svg name, name of svg after transition
// Use for links with SVG background images
// when both SVG and text need to change color on hover using the same transition.
@mixin link-with-svg($start-svg:null, $end-svg:null) {
  & {
    position: relative;
    background-image: url("../img/#{$start-svg}.svg");
    background-repeat: no-repeat;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: url("../img/#{$end-svg}.svg");
      background-repeat: no-repeat;
      transition: opacity 0.6s cubic-bezier(0.77, 0, 0.175, 1);
      opacity: 0;
    }

    &:hover {
      &::before {
        opacity: 1;
      }
    }
  }
}
