#footer {
  //margin-top: 120px;
  .section {
    max-width: $width--large;
    margin: 0 auto;
    padding: 30px 50px;
    color: $color--white;
    font-style: normal;
    font-stretch: normal;
    line-height: 32px;
    letter-spacing: 0.2px;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    font-weight: 500;
    overflow: auto;

    @include breakpoint(0 $width--tablet) {
      padding-left: 15px;
      padding-right: 15px;
    }
    @include breakpoint($width--medium) {
      //font-size: 16px;
    }
  }
}

.footer-bottom {
  border-top: 1px solid $color-grey-blue;
  font-size: 14px;
  font-weight: 300;
  margin-top: 32px;

  @include breakpoint($width--medium) {
    padding-top: 8px;
  }
}

.footer--copyright {
  padding: 5px 10px;
  margin: 0 0;
  width: 100%;
  float: none;
  @include breakpoint($width--medium) {
    float: left;
    width: 35%;
  }
}

nav.footer--navigation {
  width: 100%;
  float: none;
  li {
    border-bottom: 1px solid #9d9d9d;
    padding: 5px 10px;
    @include breakpoint($width--medium) {
      display: inline-block;
      border-bottom: none;
    }
  }

  @include breakpoint($width--medium) {
    width: 65%;
    text-align: right;
    float: right;
  }
}
