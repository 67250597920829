.paragraphs-item-gallery {
  //owl carousel
  .owl-carousel {
    .owl-stage-outer {
      position: relative;

      .owl-item {
        figcaption {
          padding: 20px;
          padding-bottom: 0;
          color: $color-grey-blue;
          font-size: 14px;

          h1, h2, h3, h4, h5, h6 {
            font-family: $main-font--header;
            font-size: 20px;
          }
        }
      }
    }

    .owl-nav {
      position: absolute;
      width: 100%;
      top: calc(50% - 100px);

      .owl-prev, .owl-next {
        border: 2px solid #fff;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        display: inline-block;
        text-align: center;
        color: white;
        background: $color--blue;
        font-size: 0;
        line-height: 0;

        &:before {
          font-family: FontAwesome;
          content: "\f053";
          font-size: 20px;
          line-height: 0.75;
          color: #fff;
          font-weight: 300;
        }

        &:hover {
          background: $color--navy;
        }

        @include breakpoint($bp--small-down) {
          width: 40px;
          height: 40px;
        }
      }

      .owl-prev {
        position: absolute;
        left: 10px;

        @include breakpoint($bp--small-down) {
          display: none;
        }
      }

      .owl-next {
        position: absolute;
        right: 10px;

        &:before {
          content: "\f054";
        }
      }

      @include breakpoint($bp--small-down) {
        top: calc(50% - 90px);
      }
    }
  }

  // general spacing
  p {
    margin-bottom: 20px;
  }
}
