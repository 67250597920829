
body.page-people{
  .node-ucsf-person {
    overflow: visible;
  }
}

a {
  font-weight: 400;
}

#page #header .region-header .block-search {
  .contextual-links-wrapper {
    @include breakpoint(0 $width--medium) {
      display: none;
    }
  }
}
html body .region-sidebar .more-link {
  display: none;
}

html body .more-link {
  position: absolute;
  right: 0px;
  top: 24px;
  font-weight: bold;
  a {
    border: 1px solid $color--blue;
    padding: 5px 20px;
    border-radius: 3px;
    color: $color--blue;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: .05em;
    &:hover {
      color: $color--blue;
      border: 1px solid $color--blue;
      background: $color--grey2--tint2;
    }
    &:active {
      background: $color--blue;
      color: $color--white;
      border: 1px solid $color--blue;
    }
  }
}

// #sidebar {
//   .block-menu-block {
//     li {
//       padding-left: 0 !important;
//       &:before {
//         content: none !important;
//       }
//     }
//     li.expanded {
//       > ul {
//         > li {
//           > a {
//             padding-left: 50px;
//           }
//         }
//       }
//     }
//   }
//   .block-menu {
//     li {
//       &:before {
//         content: none !important;
//       }
//     }
//     li.expanded > ul {
//       >li > a {
//         padding-left: 40px;
//       }
//     }
//   }
//   h2 {
//     line-height: 1;
//   }
//   .block  {
//     li {
//       list-style-position: before;
//           position: relative;
//     padding-left: 14px;
//     margin-bottom: 8px;
//       &:before {
//             content: "\2022";
//         line-height: 0;
//         display: inline-block;
//         width: 14px;
//         margin-left: -14px;
//       }
//     }
//   }
// }

#node_page_form_group_sidebar {
  .block-ucsf-b1gfoot-xmlevents {
    margin-bottom: 30px !important;
  }
  #block-ucsf-b1gfoot-xmlnews-startingpoint-xmlnews--2 {
    margin-bottom: 30px !important;
  }
  .block-views {
    overflow: hidden;
    clear: both;
  }
  #block-views-events-events-block--2 {
    margin-bottom: 20px;
  }
  .block-ucsf-b1gfoot-xmlevents  {
    .event {
      position: relative;
      margin-bottom: 30px;
      border-bottom: 1px solid $color--grey2;
      padding-bottom: 30px;
      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }
      .date {
        padding-left: 85px;
        @include breakpoint(770px 1000px) {
          padding-left: 0;
        }
      }
      .time {
        padding-left: 85px;
        @include breakpoint(770px 1000px) {
          padding-left: 0;
        }
      }
    }
    h3 {
      padding-left: 85px;
      font-size: 18px;
      @include breakpoint(770px 1000px) {
        padding-left: 0;
      }
    }
    .event-image {
      float: left;
      width: 62px;
      border-collapse: separate;
      border: 1px solid transparent;
      @include border-radius(4px);
      overflow: hidden;
      @include breakpoint(770px 1000px) {
        float: none;
        width: 100%;
      }
      .day {
        text-align: center;
        text-transform: uppercase;
        color: #fff;
        font-weight: bold;
        font-size: 12px;
        letter-spacing: .08em;
        padding-top: 2px;
        padding-bottom: 2px;
        background-color: none;
      }
      .number-date {
        text-align: center;
        font-size: 45px;
        line-height: .9em;
        padding-top: 5px;
        color: #FFF;
        display: block;
        background-color: none;
        .font--header--granjon & {
          font-family: $secondary-font;
        }
      }
    }
  }
}
