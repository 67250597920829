body.header--blue {
  .region-content-top {
    background: $color--blue;
  }
}

body.header--navy {
  .region-content-top {
    background: $color--navy;
  }
}
body.header--teal {
  .region-content-top {
    background: $color--teal;
  }
}
body.header--purple {
  .region-content-top {
    background: $color--purple;
  }
}
body.header--light--navy {
  .region-content-top {
    background: $color--navy--tint1;
  }
}
body.header--dark-grey {
  .region-content-top {
    background: $color--dark-grey;
  }
}
