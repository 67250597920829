#page-title {
  max-width: 1320px;
  margin: 0 auto 20px auto;
  padding-left: 15px;
  padding-right: 15px;
  @include breakpoint($bp--medium-up) {
    padding-left: 50px;
    padding-right: 50px;
  }
}
