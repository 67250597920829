// .ucsf-person {
//   border-bottom: 1px solid $color--grey2;
//   overflow: auto;
//   clear:both ;
//   padding-bottom: 15px;
//   margin-bottom: 15px;
//   .person-photo {
//     float: left;
//     margin-right: 50px;
//   }
//   .person-title-name {
//     // font-size: 24px;
//     // font-weight: 300;
//   }
//   .person-working-title {
//     margin-bottom: 10px;
//   }
//   .person-address {
//     clear: right;
//     overflow: hidden;
//     .address-description {
//       float: left;
//       font-weight: bold;
//       margin-right: 8px;
//     }
//   }
//   .person-phone {
//     .phone-description {
//       float: left;
//       font-weight: bold;
//       margin-right: 8px;
//     }
//   }
//   .person-email {
//     .email-description {
//       float: left;
//       font-weight: bold;
//       margin-right: 8px;
//     }
//   }
// }

.node-ucsf-person,
.node-other-person {
  overflow: hidden;
  .field-name-field-person-headshot-photo,
  .field-name-field-person-headshot-default {
    float: left;
    margin-right: 40px;
    width: 150px;
  }
  & > h4,
  & > h2,
  & > h1 {
    font-family: $main-font;
    font-weight: $font--light;
    font-size: 26px;
    margin-top: 0;
    margin-bottom: 10px;
    a {
      color: #000;
    }
  }
  .field-name-field-person-title-override,
  .field-name-field-person-working-title,
  .field-name-field-department-override,
  .field-name-field-person-primary-dept {
    font-size: 18px;
  }
  .field-name-field-person-email {
    font-size: 15px;
    margin-top: 8px;
  }
  .field-name-field-person-research-biography,
  .field-name-field-person-what-i-do-text {
    padding-top: 15px;
    font-size: 15px;
    clear: both;
  }
  .field-name-field-person-pub-override-list,
  .field-name-field-person-publications-list {
    margin-top: 50px;
  }
}

// Special use case for embed view
.field-name-field-pg-ref-profiles {
  .node-ucsf-person,
  .node-other-person {
    .field-name-field-person-research-biography,
    .field-name-field-person-what-i-do-text {
      clear: none;
      p {
        margin-bottom: 0;
      }
    }
  }
  & > .field-items > .field-item {
    padding-bottom: 32px;
    margin-bottom: 40px;
    border-bottom: 1px solid $color--grey2;
    &:last-child {
      border: none;
      padding-bottom: 0;
    }
  }
}

.block-ucsf-b1gfoot-profile {
  .content {
    position: relative;
  }
  .more-link {
    top: -63px;
  }
  .node-ucsf-person,
  .node-other-person {
    .field-name-field-person-research-biography,
    .field-name-field-person-what-i-do-text {
      clear: none;
      p {
        margin-bottom: 0;
      }
    }
  }
  & > .content > .node-teaser {
    padding-bottom: 32px;
    margin-bottom: 40px;
    border-bottom: 1px solid $color--grey2;
    &:last-child {
      border: none;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
}

#main #content .block-ucsf-b1gfoot-profile > h3 {
  padding-right: 120px;
}

.profile--expanded .view-display-id-people_page {
  .node-ucsf-person,
  .node-other-person {
    .field-name-field-person-research-biography,
    .field-name-field-person-what-i-do-text {
      clear: none;
      p {
        margin-bottom: 0;
      }
    }
  }
  .views-row {
    padding-bottom: 32px;
    margin-bottom: 40px;
    border-bottom: 1px solid $color--grey2;
    &:last-child {
      border: none;
      padding-bottom: 0;
    }
  }
  & > .item-list {
    clear: both;
  }
}

.profile--grid .view-display-id-people_page {
  overflow: hidden;
  & > .item-list {
    clear: both;
  }
  .views-row {
    @include breakpoint($bp--small-up) {
      @include gallery(2, 3%, 60px);
      float: left;
    }
    @include breakpoint(760px) {
      @include gallery(3, 3%, 60px);
    }
    @include breakpoint($bp--960-up) {
      @include gallery(4, 3%, 60px);
    }

  }
  .node-ucsf-person,
  .node-other-person {
    max-width: 180px;
    margin-left: auto;
    margin-right: auto;
    @include breakpoint($bp--small-up) {
      margin: 0;
    }
    .field-name-field-person-headshot-photo,
    .field-name-field-person-headshot-default {
      float: none;
      margin: 0 0 25px 0;
      width: 180px;
    }
    & > h4,
    & > h2,
    & > h1 {
      font-size: 18px;
      font-weight: $font--normal;
    }
    .field-name-field-person-title-override,
    .field-name-field-person-working-title,
    .field-name-field-department-override,
    .field-name-field-person-primary-dept {
      font-size: 15px;
    }
    .field-name-field-person-research-biography,
    .field-name-field-person-what-i-do-text {
      display: none;
    }
  }
}

body.sidebar.profile--grid .view-display-id-people_page {
  .views-row {
    @include breakpoint(760px) {
      @include gallery(2, 3%, 60px);
    }
    @include breakpoint($bp--960-up) {
      @include gallery(2, 3%, 60px);
    }
    @include breakpoint(1070px) {
      @include gallery(3, 3%, 60px);
    }
  }
}

#block-views-exp-people-people-page {
  label {
    font-weight: $font--normal;
    margin-bottom: 5px;
  }
  select {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
  }
  #edit-field-person-type-tid-wrapper {
    padding-bottom: 40px;
    margin-bottom: 20px;
    border-bottom: 1px solid $color--grey2;
    width: 100%;
  }
}
.sidebar-color--grey .sidebar {
  select {
    background: $color--white;
  }
}
.region-sidebar {
  .field-name-field-pg-ref-profiles > .field-label {
    display: none;
  }
  .field-name-field-pg-ref-profiles > .field-items > .field-item {
    border-bottom: 1px solid $color--grey2 !important;
    &:last-child {
      border: none !important;
      padding-bottom: 0;
    }
  }
  .node-ucsf-person,
  .node-other-person {
    .field-name-field-person-headshot-photo,
    .field-name-field-person-headshot-default {
      float: none;
      margin: 0 0 25px 0;
      width: 150px;
    }
    .field-item {
      border: none !important;
      margin-bottom: 0 !important;
    }
    & > h4,
    & > h2,
    & > h1 {
      font-size: 18px !important;
      font-weight: $font--normal;
      font-family: $main-font;
    }
    .field-name-field-person-title-override,
    .field-name-field-person-working-title,
    .field-name-field-department-override,
    .field-name-field-person-primary-dept {
      font-size: 15px;
    }
    .field-name-field-person-research-biography,
    .field-name-field-person-what-i-do-text {
      display: none;
    }
  }
}
.people-glossary {
  margin-bottom: 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid #E1E1E1;
  ul li {
    display: inline-block;
    margin: 0 4px 0 0;
    font-size: 15px;
    a:hover  {
      color: #051F46;
    }
  }
  .active {
    color: #000;
    font-weight: bold;
  }
}
