#block-search-form {
  ::-webkit-input-placeholder {
     font-size: 15px;
  }
  :-moz-placeholder { /* Firefox 18- */
     font-size: 15px;
  }

  ::-moz-placeholder {  /* Firefox 19+ */
     font-size: 15px;
  }

  :-ms-input-placeholder {
     font-size: 15px;
  }
  input[type=text] {
    border: 1px solid #FFF;
    border-bottom: 1px solid #525860;
    width: 173px;
    font-size: 15px;
    position: relative;
    padding: 5px;
    padding-right: 30px;
    font-family: $main-font;
    &:focus {
      border: 1px solid #0071AD;
      margin: 0;
      padding: 5px;
      padding-right: 30px;
    }
  }
  .container-inline {
    padding: 0;
    .search-icon {
      position: absolute;
      right: 4px;
      top: 15px;
      color: #58595b;
      font-size: 18px;
      cursor: pointer;
    }
  }
}
#header {
  .nav-collapse {
    #mobile-search-block {
      float: none;
      padding-top: 16px;
      padding-bottom: 16px;
      padding-left: 15px;
      width: 100%;
      padding-right: 15px;
      .text-input {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 15px;
        width: 100%;
        font-family: $main-font;
      }
      .search-icon {
        display: none;
      }
    }
  }
}
