body.page-node-add-blog .field-name-field-term-blog-topics,
body.page-node-edit.node-type-blog .field-name-field-term-blog-topics {
  .form-checkboxes {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    & > .form-item {
      flex: 0 0 25%;
      width: 25%;
      display: inline-block;
    }
  }
}

.page-blog {
  #page-title {
    display: none;
  }
  &.sidebar--right {
    @include breakpoint(0 $bp--tablet-up) {
      #sidebar {
        display: none;
      }
    }
  }
  .mobile-filters {
    margin-bottom: 50px;
    @include breakpoint($bp--small-up) {
      flex-direction: row;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
    }
    @include breakpoint($bp--medium-up) {
      display: none;
    }
    .views-exposed-widget {
      flex: 1 auto;
      flex-basis: 0;
      border-radius: 4px;
      background-color: #EDEDED;
      box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2), 1px 1px 4px 0 rgba(0,0,0,0.1);
      margin-right: 15px;
      margin-bottom: 10px;
      .selector {
        text-transform: uppercase;
        color: $color--black;
        font-weight: bold;
        font-size: 14px;
        padding: 6px 0px 6px 0px;
        text-align: center;
        position: relative;
        &:hover {
          cursor: pointer;
        }
        &:after {
          content: "";
          width: 4px;
          height: 4px;
          border: solid black;
          border-width: 0 2px 2px 0;
          display: inline-block;
          padding: 2px;
          position: absolute;
          right: 10px;
          top: 7px;
          transform: rotate(45deg);
        }
        &.open {
          &:after {
            transform: rotate(-135deg);
            top: 12px;
          }
          & ~ .options {
            display: block;
          }
        }
      }
      .options {
        display: none;
        padding: 10px 25px 25px 25px;
        a {
          display: block;
          padding: 2px 0;
          &.active {
            color: $color--text;
          }
        }
      }
    }

  }
}

#block-ucsf-b1gfoot-blog-startingpoint-blog-header {
  margin-bottom: 50px;
}
.blog-header-background {
  &.with-image {
    position: relative;
    @include breakpoint($bp--small-up) {
      height: 134px;
    }
    @include breakpoint($bp--medium-up) {
      height: 230px;
    }
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 1;
    }
    .bg-image {
      background-size: cover;
      background-position: center center;
      position: relative;
      padding-top: 65px;
      filter: grayscale(100%);
      @include breakpoint($bp--small-up) {
        height: 134px;
        padding-top: 0;
      }
      @include breakpoint($bp--medium-up) {
        height: 230px;
      }
    }
    h2 {
      margin-bottom: 0px;
      padding-bottom: 10px;
      margin-left: -15px;
      margin-right: -15px;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 15px;
      position: relative;
      z-index: 2;
      @include breakpoint($bp--small-up) {
        position: absolute;
        bottom: 0;
        margin: 0;
        padding: 0 0 10px 0;
      }
      @include breakpoint($bp--medium-up) {
        max-width: 65%;
      }
    }
    & ~ .inside p {
      padding: 0px 0 20px 0;
      @include breakpoint($bp--small-up) {
        padding: 20px 0;
      }
    }
  }
  &.without-image {
    h2 {
      padding-top: 30px;
      margin-bottom: 10px;
      @include breakpoint($bp--medium-up) {
        max-width: 65%;
      }
    }
    & ~ .inside p {
      padding: 0 0 35px 0;
    }
  }
  .inside {
    position: relative;
  }
  h2 {
    @extend h1;
    font-size: 33px !important;
    @include breakpoint($bp--small-up) {
      font-size: 56px !important;
    }
  }
  & ~ .inside p {
    font-family: $main-font--light;
    font-size: 18px;
    @include breakpoint($bp--medium-up) {
      font-size: 22px;
      max-width: 65%;
    }
  }
}

.node-blog {
  .field-name-field-ref-author {
    margin-bottom: 40px;
    .field-name-field-image {
      float: left;
      img {
        width: 80px;
        border-radius: 50%;
        margin-right: 20px;
      }
    }
  }
  .byline {
    padding-top: 17px;
  }
  .byline-author {
    font-weight: bold;
    a:hover {
      color: $color--link;
    }
  }
  .byline-date {
    font-style: italic;
    color: #797979;
  }
  .share-buttons {
    margin-top: 33px;
  }
}

.node-blog-author {
  & > h1 {
    margin-bottom: 0;
  }
  .field-name-field-image {
    float: right;
    margin-top: -42px; // Aligning image with node title
    margin-left: 25px;
    margin-bottom: 10px;
    img {
      border-radius: 50%;
      max-width: 180px;
      max-height: 180px;
    }
  }
  .field-name-field-author-text-titles {
    margin-bottom: 30px;
    .field-item {
      @extend h4;
      margin-top: 0;
      margin-bottom: 0;
    }
  }


  .field-name-body,
  .field-name-body > .field-items,
  .field-name-body > .field-items > .field-item {
    display: inline; // To have the text wrapping around the image without having to rewrite the template itself
  }
}


.view-display-id-blog_page {

  .views-row {
    overflow: hidden;
    border-bottom: 1px solid #979797;
    padding-bottom: 50px;
    margin-bottom: 50px;
    &.views-row-last {
      border-bottom: none;
    }
  }

  .blog-leftcolumn {
    overflow: hidden;
    // margin-bottom: 25px;
    @include breakpoint($bp--medium-up) {
      float: left;
      width: 150px;
      text-align: right
    }
  }
  .blog-author-thumbnail {
    line-height: 0;
    margin-bottom: 15px;
    @include breakpoint(0 $bp--tablet-up) {
      float: left;
      margin-right: 15px;
    }
    img {
      max-width: 50px;
      height: auto;
      border-radius: 50%;
      @include breakpoint($bp--medium-up) {
        max-width: 80px;

      }
    }
  }
  .blog-author-name {
    font-weight: bold;
    @include breakpoint(0 $bp--tablet-up) {
      padding-top: 5px;
    }
    a:hover {
      color: $color--link;
    }
  }
  .date-created {
    font-style: italic;
    color: #797979;
    margin-bottom: 20px;
    font-size: 14px;
  }
  .topics {
    font-size: 14px;
    @include breakpoint(0 $bp--tablet-up) {
      display: none;
    }
  }
  .views-field-title,
  .field-name-body,
  .views-more-link {
    @include breakpoint($bp--medium-up) {
      margin-left: 200px;
    }
  }
  .field-name-body .field-item > *:last-child {
    margin-bottom: 0;
  }
  .views-field-title a {
    color: $color--text;
    &:hover {
      color: $color--link;
    }
  }
  .views-more-link {
    display: block;
    font-weight: bold;
    margin-top: 20px;
  }

  .filtered-by {
    overflow: hidden;
    margin-bottom: 50px;
    padding-bottom: 5px;
    letter-spacing: 1px;
    .label {
      float: left;
      text-align: right;
      text-transform: uppercase;
      color: $color--black;
      font-weight: bold;
      font-size: 14px;
      padding-top: 5px;
      margin-right: 20px;
      margin-bottom: 10px;
      @include breakpoint($bp--medium-up) {
        width: 150px;
      }
    }
    .filters {
      @include breakpoint($bp--medium-up) {
        margin-left: 200px;
      }
      a {
        display: inline-block;
        position: relative;
        text-transform: uppercase;
        color: $color--black;
        font-weight: bold;
        font-size: 14px;
        padding: 6px 20px 6px 40px;
        border-radius: 4px;
        background-color: #EDEDED;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2), 1px 1px 4px 0 rgba(0,0,0,0.1);
        margin-right: 15px;
        margin-bottom: 10px;
        &:hover {
          opacity: 0.6;
        }
        &:before,
        &:after {
          content: "";
          position: absolute;
          height: 16px;
          width: 16px;
          width: 2px;
          left: 15px;
          top: 8px;
          background-color: #979797;
        }
        &:before {
          transform: rotate(45deg);
        }
        &:after {
          transform: rotate(-45deg);
        }
      }
    }
    & ~ .mobile-filters {
      border-top: 1px solid #DEDEDE;
      padding-top: 30px;
      margin-top: -30px;
    }
  }
  .view-empty {
    .placeholder {
      font-size: 20px;
      text-align: center;
      margin-top: 100px;
      font-style: italic;
      color: $color--dark-grey;
    }
  }
  .more-link {
    position: relative;
    text-transform: uppercase;
    text-align: left;
    top: 0;
    left: 0;
    @extend .btn--small;
    @extend .btn--white--underline;
    &:before {
      content: "";
      display: block;
    }
  }
}

#block-views-blog-related-author-block,
#block-views-blog-by-author-block {
  margin-top: 50px;
  .views-row {
    border-bottom: 2px dashed #B4B9BF;
    padding: 20px 0;
    &.views-row-last {
      border-bottom: none;
    }
    &.views-row-first {
      padding-top: 0;
    }
  }
  .views-field-created {
    font-style: italic;
    font-size: 14px;
  }
  .view-footer {
    margin-top: 15px;
    a {
      color: $color--text;
      font-weight: bold;
      &:hover {
        color: $color--link;
      }
    }
  }
}

// Ugly workaround to avoid any custom code making things less flexible for editors
#block-views-blog-blog-view-all-block {
  margin-bottom: 0 !important;
  .view-header {
    font-weight: bold;
    font-size: 14px;
  }
  .view-content,
  .item-list {
    display: none;
  }
  &:after {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
    background-color: #979797 !important;
    height: 1px !important;
  }
}

#block-views-exp-blog-blog-page {
  .views-exposed-widget {
    float: none;
  }
  label {
    font-size: 14px;
  }
  div[class$=-all] {
    display: none;
  }
  .bef-select-as-links {
    padding-left: 10px;
    font-size: 14px;
    margin-top: 15px;
    margin-bottom: 10px;
  }
  a.active {
    color: $color--text;
  }
}

.post-more-topics {
  font-weight: bold;
}

.post-more-topics,
.filters-more-dates,
.filters-more-authors,
.filters-more-topics {
  color: $color--black;
  &:after {
    content: "";
    height: 0px;
    width: 0px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 8px solid $color--black;
    display: inline-block;
    margin-left: 8px;
  }
}

#block-views-blog-blog-topics-block,
#block-views-blog-blog-authors-block,
#block-views-blog-blog-publicationdate-block {
  & > h3 {
    font-size: 14px;
    font-family: $main-font;
    font-weight: bold;
  }
  .view-content {
    padding-left: 10px;
    font-size: 14px;
  }
  .views-row {
    padding-bottom:3px;
    &.views-row-last {
      padding-bottom: 0;
    }
  }
  &:after {
    content: none !important;
  }
}
// Bypassing default spacing for all sidebar blocks
#block-views-blog-blog-authors-block {
  margin-top: 28px !important;
}
#block-views-blog-blog-topics-block {
  margin-top: 28px !important;
  margin-bottom: 0 !important;
}
#block-views-blog-blog-publicationdate-block {
  margin-top: 20px !important;
}


.view-display-id-blog_block {
  position: relative;
  .content {
    position: relative;
  }
  .more-link {
    top: -63px;
  }
  .ucsf-blog {
    overflow: hidden;
    // margin-bottom: 50px;
    @include breakpoint($bp--medium-up) {
      margin-bottom: 20px;
      @include gallery(3, 8%, 0%);
    }

    &:last-child,
    &:nth-child(3) {
      margin-bottom: 0;
    }

    &:nth-child(4) {
      display: none;
    }
  }
  .content h3,
  .view-content h3 {
    margin-top: 0;
    font-size: 18px;
    line-height: 1.3;
    overflow: hidden;
    margin: 0;
    font-family: $main-font;
    font-weight: 400;
    margin-bottom: 12px;
    @include breakpoint($bp--medium-up) {
      margin-bottom: 0;
    }
    a {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
  .date {
    font-weight: bold;
    font-size: 12px;
    color: #989898;
    margin-bottom: 5px;
  }
}

.region-content {
  .view-display-id-blog_block {
    h3 a {
      color: $color--black;
      &:hover {
        color: $color--blue;
      }
    }
  }
}

// Sidebar styles
body.sidebar {
  .view-display-id-blog_block .ucsf-blog {
    @include breakpoint($bp--medium-up) {
      @include gallery(2, 8%, 3%);
    }
    &:last-child {
      margin-bottom: 0;
    }
    &:nth-child(4) {
      display: block;
    }
  }
}


body.sidebar .region-sidebar .view-display-id-blog_block .ucsf-blog,
.field-name-field-paragraph-sidebar .view-display-id-blog_block .ucsf-blog,
.field-name-field-blockref-sidebar .view-display-id-blog_block .ucsf-blog {
  @include breakpoint($bp--medium-up) {
    @include gallery(1, 0);
    &:nth-child(n) {
      float: none;
    }
  }
  &:last-child,
  &:nth-child(3) {
    margin-bottom: 0;
  }
  &:nth-child(4) {
    display: none;
  }
  overflow: hidden;
  border-top: 1px solid $color--grey2;
  padding-top: 28px;
  margin-top: 28px;
  &:first-child {
    border-top: none;
    padding-top: 0;
    margin-top: 0;
  }
}
