body:not(.cke_editable) {
  .videodetector {
    position      : relative;
    width         : 100%;
    height        : 0;
    padding-bottom: 60%;
  }

  .videodetector iframe {
    position: absolute;
    top     : 0;
    left    : 0;
    width   : 100%;
    height  : 100%;
  }
}

