.special-header {
  &.slider-secondary {
    .region-content-top {
      height: auto;
      max-width: 100%;
      margin-bottom: 50px;
    }
  }
}
.full-width-banner {
  color: $color--white;
  width: 100%;
  clear: both;
  overflow: auto;
  padding: 30px 0;
  &.header--blue {
  	background: $color--blue;
  }
  &.header--navy {
  	background: $color--navy;
  }
  &.header--purple {
  	background: $color--purple;
  }
  &.header--light--navy {
  	background: $color--navy--tint1;
  }
  &.header--dark-grey {
  	background: $color--dark-grey;
  }
  &.header--teal {
  	background: $color--teal;
  }
  a {
    color: $color--white;
    &:hover {
      font-weight: bold;
    }
  }
  .full-width-banner--wrapper {
    max-width: $width--large;
    margin: 0 auto !important;
    padding-left: 50px;
    padding-right: 50px;
    .full-width-banner--content {
      float: none;
      width: 100%;
      margin-bottom: 25px;
      font-size: 24px;
      font-family: $secondary-font;
      h1 {
        font-size: 56px;
      }
    }
    .full-width-banner--sidebar {
      float: none;
      width: 100%;
      font-size: 18px;
    }
    p, ul, ol, li {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
@media (min-width: $width--medium) {
  .full-width-banner {
    .full-width-banner--wrapper {
      .full-width-banner--content {
        float: right;
        width: 63.6%;
      }
      .full-width-banner--sidebar {
        float: left;
        width: 27.21%;
      }
    }
  }

  // Set float for right sidebar
  body.sidebar--right, body.no-sidebar--right {
    .full-width-banner--content {
      float: left !important;
    }
    .full-width-banner--sidebar {
      float: right !important;
    }
  }
}
