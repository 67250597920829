.breadcrumb {
  padding-bottom: 0;
  margin-top: 2px;
  margin-bottom: 12px;
  margin-left: 0px;
  color: #525860;
  display: none;
  @include breakpoint($bp--small-up) {
    display: block;
  }
  a {
    font-size: .9em;
    margin-right: 5px;
  }
  a ~ a {
    margin-left: 5px;
  }
  .breadcrumb-page-title {
    color: #525860;
    font-size: .9em;
  }
}
