body.meganav--open {
  @include breakpoint($bp--medium-up) {
    overflow: hidden;
    max-height: 100vh;

    &.admin-menu {
      max-height: calc(100vh - 34px);
    }
  }
}

.meganav {
  display: none;
  @media (min-width: 769px) {
    display: block;
  }
  background: #4E5365;
  transition: max-height 0.6s cubic-bezier(0.77, 0, 0.175, 1);
  height:100vh;
  max-height:100vh;
  position: fixed;
  width: 100%;
  z-index: 10;
  overflow: auto;
  .meganav-container {
    background: $color--grey-pale;
  }
  &.collapsed {
    max-height:0;
    // .inside {
    //   display: none;
    // }
  }
  .close-meganav {
    // needs to be centered based off "header-give" element;
    // so offset right needs to be container padding + half of the "header-give" element - half of the button width;
    position: absolute;
    right: 82px; // 50 + (96/2) - (32/2)
    background: url("../img/x.svg") no-repeat;
    width: 32px;
    height: 32px;
    cursor: pointer;
    @include breakpoint($bp--large-up) {
      right: 97px; // 50 + (126/2) - (32/2)
    }
  }
  .inside {
    // transition: display 0.3s ease-out;
    position: relative;
    display: flex;
    max-width: $width--large;
    margin: 0 auto;
    padding: 64px 50px 72px 50px;
  }
  .meganav-left {
    width: 40%;
    border-right: 1px solid $color--grey1;
    padding-right: 8%;
    font-size: 18px;
    line-height: 1.78;

    .ucsf-logo {
      width: 178px;
      height: 116px;
      background: url("../img/ucsf-sig-white-rgb.svg") no-repeat;
      background-size: contain;
      margin-bottom: 40px;
    }
    .ucsf-desc {
      font-family: $main-font--light;
      color: $color--navy;
    }
    .menu-left {
      font-weight: $font--bold;
      .menu-item {
        margin-top: 24px;
        &.doctor a {
          // mixin needs name of starting svg and name of svg on hover
          @include link-with-svg(doctor, doctor-navy);
        }
        &.refer a {
          @include link-with-svg(refer, refer-navy);
        }
        &.building a {
          @include link-with-svg(building, building-navy);
        }
        & > a {
          @include link-default();
          display: inline-block;
          padding-left: 40px;
          transition: color .6s cubic-bezier(0.77, 0, 0.175, 1);
        }
      }
    }
  }
  .meganav-right {
    width: 60%;
    padding-left: 8%;
    padding-right: 13%;

    .form-search {
      position: relative;
      .search {
        width: 100%;
        box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.04);
        border: none;
        padding: 16px 24px;
        font-family: $main-font--light;
        font-size: 18px;
        color: $color--navy--tint1;
        line-height: 32px;
        &::placeholder {
          color: $color--navy--tint1;
        }
        &:focus {
          background: $color--white;
        }
      }
      .search-icon {
        position: absolute;
        right: 24px;
        top: 20px;
        background: url("../img/search.svg") no-repeat;
        width: 24px;
        height: 24px;
        cursor: pointer;
        text-indent: -9999px;
      }
    }
    .menu-right {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 75px;
      grid-row-gap: 64px;
      margin-top: 40px;
      .menu-header {
        font-size: 12px;
        font-weight: bold;
        line-height: 1.33;
        letter-spacing: 1.6px;
        color: $color--navy;
        text-transform: uppercase;
        margin-bottom: 16px;
      }
      .menu-item {
        font-size: 14px;
        line-height: 1.71;
        letter-spacing: 0.3px;
        margin-bottom: 8px;
        & > .menu-link {
          @include link-dark-to-light();
        }
      }
    }
  }

}
