div.vertical-tabs {
  margin: 1em 0 1em 15em;
  border: 1px solid $color--border;
}
.vertical-tabs ul.vertical-tabs-list {
  width: 15em;
  list-style: none;
  border-top: 1px solid $color--border;
  padding: 0;
  margin: -1px 0 -1px -15em;
  float: left;
}
.vertical-tabs fieldset.vertical-tabs-pane {
  margin: 0 !important;
  padding: 0 1em;
  border: 0;
}
fieldset.vertical-tabs-pane legend {
  display: none;
}
fieldset.vertical-tabs-pane fieldset legend {
  display: block;
}

/* Layout of each tab */
.vertical-tabs ul.vertical-tabs-list li {
  background: $color--grey2--tint3;
  border: 1px solid $color--border;
  border-top: 0;
  padding: 0;
  margin: 0;
}
.vertical-tabs ul.vertical-tabs-list li a {
  display: block;
  text-decoration: none;
  padding: 0.5em 0.6em;
}
.vertical-tabs ul.vertical-tabs-list li a:focus strong,
.vertical-tabs ul.vertical-tabs-list li a:active strong,
.vertical-tabs ul.vertical-tabs-list li a:hover strong {
}
.vertical-tabs ul.vertical-tabs-list li a:hover {
  outline: none;
}
.vertical-tabs ul.vertical-tabs-list li.selected {
  background-color: $color--white;
  border-right-width: 0;
}
.vertical-tabs ul.vertical-tabs-list .selected strong {
  color: $color--black;
}
.vertical-tabs ul.vertical-tabs-list .summary {
  display: block;
  font-style: italic;
  color: $color--black--tint2;
  font-size: 12px;
}
.vertical-tabs ul.vertical-tabs ul.vertical-tabs-list .summary {
  line-height: normal;
  margin-bottom: 0;
}

/**
 * Prevent text inputs from overflowing when container is too narrow. "width" is
 * applied to override hardcoded cols or size attributes and used in conjunction
 * with "box-sizing" to prevent box model issues from occurring in most browsers.
*/
.vertical-tabs .form-type-textfield input {
  width: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}



.vertical-tabs {
  .vertical-tab-button a {
    margin-left: -1px;
  }
  .selected a {
    border-left: 5px solid $color--blue;
  }
}
