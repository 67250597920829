// From http://sassmeister.com/gist/1b4f2da5527830088e4d

////
// I have written a lot of different `str-replace` Sass functions over
// the months yet none of my tries were succeeding in making the new substring
// able to contain the one to replace (e.g. `str-replace($str, 'a', 'ab')`).
// Thanks to Valérian Galliat (@valeriangalliat), I finally managed to build
// a bulletproof `str-replace` Sass function that allows new string to contain
// the old one.
////

/// Replace `$search` with `$replace` in `$string`
/// @param {String} $string - Initial string
/// @param {String} $search - Substring to replace
/// @param {String} $replace ('') - New value
/// @return {String} - Updated string
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

@function url-encode($string) {
  $encodeMap:('%':'%25',
              ' ':'%20',
              '!':'%21',
              '\"':'%22',
              '#':'%23',
              '$':'%24',
              '&':'%26',
              '\'':'%27',
              '(':'%28',
              ')':'%29',
              '*':'%2A',
              '+':'%2B',
              ',':'%2C',
              '-':'%2D',
              '.':'%2E',
              '/':'%2F',
              ':':'%3A',
              ';':'%3B',
              '<':'%3C',
              '=':'%3D',
              '>':'%3E',
              '?':'%3F',
              '@':'%40',
              '[':'%5B',
              '\\':'%5C',
              ']':'%5D',
              '^':'%5E',
              '_':'%5F',
              '`':'%60',
              '{':'%7B',
              '|':'%7C',
              '}':'%7D',
              '~':'%7E',
              '¢':'%A2',
              '£':'%A3',
              '¥':'%A5',
              '§':'%A7',
              '«':'%AB',
              '¬':'%AC',
              '¯':'%AD',
              'º':'%B0',
              '±':'%B1',
              'ª':'%B2',
              'µ':'%B5',
              '»':'%BB',
              '¼':'%BC',
              '½':'%BD',
              '¿':'%BF',
              'À':'%C0',
              'Á':'%C1',
              'Â':'%C2',
              'Ã':'%C3',
              'Ä':'%C4',
              'Å':'%C5',
              'Æ':'%C6',
              'Ç':'%C7',
              'È':'%C8',
              'É':'%C9',
              'Ê':'%CA',
              'Ë':'%CB',
              'Ì':'%CC',
              'Í':'%CD',
              'Î':'%CE',
              'Ï':'%CF',
              'Ð':'%D0',
              'Ñ':'%D1',
              'Ò':'%D2',
              'Ó':'%D3',
              'Ô':'%D4',
              'Õ':'%D5',
              'Ö':'%D6',
              'Ø':'%D8',
              'Ù':'%D9',
              'Ú':'%DA',
              'Û':'%DB',
              'Ü':'%DC',
              'Ý':'%DD',
              'Þ':'%DE',
              'ß':'%DF',
              'à':'%E0',
              'á':'%E1',
              'â':'%E2',
              'ã':'%E3',
              'ä':'%E4',
              'å':'%E5',
              'æ':'%E6',
              'ç':'%E7',
              'è':'%E8',
              'é':'%E9',
              'ê':'%EA',
              'ë':'%EB',
              'ì':'%EC',
              'í':'%ED',
              'î':'%EE',
              'ï':'%EF',
              'ð':'%F0',
              'ñ':'%F1',
              'ò':'%F2',
              'ó':'%F3',
              'ô':'%F4',
              'õ':'%F5',
              'ö':'%F6',
              '÷':'%F7',
              'ø':'%F8',
              'ù':'%F9',
              'ú':'%FA',
              'û':'%FB',
              'ü':'%FC',
              'ý':'%FD',
              'þ':'%FE',
              'ÿ':'%FF'
  );

  @each $char, $code in $encodeMap  {
    $string: str-replace($string, $char, $code);
  }
  @return $string;
}