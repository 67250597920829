// Global styles
.block-ucsf-b1gfoot-xmlevents,
.view-display-id-events_block,
.view-display-id-events_page {
  position: relative;
  // overflow: hidden;
  & > .item-list {
    clear: both;
    margin-top: 20px;
    ul {
      margin-top: 50px;
    }
  }
  .more-link {
    top: -63px;
  }
  .event {
    margin-bottom: 50px;

    @include breakpoint($bp--medium-up) {
      margin-bottom: 20px;
      @include gallery(4, 3%);
    }
    &:last-child {
      display: block;
    }

    .event-image {
      float: left;
      width: 65px;
      @include border-radius(4px);
      overflow: hidden;
      margin-right: 16px;
      margin-bottom: 13px;
      margin-top: 3px;
      display: none;
      @include breakpoint($bp--medium-up) {
        display: block;
      }
      .day {
        text-align: center;
        text-transform: uppercase;
        color: $color--white;
        font-weight: bold;
        font-size: 12px;
        letter-spacing: .08em;
        padding-top: 2px;
        padding-bottom: 2px;
        background-color: none;
      }
      .number-date {
        text-align: center;
        font-size: 45px;
        line-height: 0.85;
        padding-top: 7px;
        color: $color--navy;
        font-family: $secondary-font;
        border-width: 1px;
        border-style: solid;
        border-radius: 0 0 8px 8px;
      }
    }
  }
  .content h3,
  .view-content h3 {
    margin-top: 0;
    font-size: 18px;
    line-height: 1.3;
    overflow: hidden;
    margin: 0;
    font-family: $main-font;
    font-weight: 400;
    margin-bottom: 12px;
    @include breakpoint($bp--medium-up) {
      margin-bottom: 0;
    }
    a {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
  .date {
    clear: both;
    width: 100%;
  }
}

// Sidebar styles
body.sidebar {
  .block-ucsf-b1gfoot-xmlevents .event,
  .view-display-id-events_block .event {
    @include breakpoint($bp--medium-up) {
      @include gallery(3, 3%);
    }
    &:nth-child(4) {
      display: none;
    }
  }
}

body.sidebar .region-sidebar .block-ucsf-b1gfoot-xmlevents .event,
.field-name-field-paragraph-sidebar .block-ucsf-b1gfoot-xmlevents .event,
.field-name-field-blockref-sidebar .block-ucsf-b1gfoot-xmlevents .event,
body.sidebar .region-sidebar .view-display-id-events_block .event,
.field-name-field-paragraph-sidebar .view-display-id-events_block .event,
.field-name-field-blockref-sidebar .view-display-id-events_block .event {
  @include breakpoint($bp--medium-up) {
    @include gallery(1, 0);
    &:nth-child(n) {
      float: none;
    }
  }
  &:nth-child(4) {
    display: none;
  }
  overflow: hidden;
  border-top: 1px solid $color--grey2;
  padding-top: 28px;
  margin-top: 28px;
  &:first-child {
    border-top: none;
    padding-top: 0;
    margin-top: 0;
  }
}

// Events page styles
.view-display-id-events_page {
  margin-bottom: 28px;
}
.view-display-id-events_page .event {
  border-top: 1px solid $color--grey2;
  padding-top: 28px;
  margin-top: 28px;
  @include gallery(1, 0);
  &:first-child {
    border-top: none;
    padding-top: 0;
    margin-top: 0;
  }
  .date-time {
    margin: 7px 0;
    color: #808285;
    font-size: 15px;
  }
  .event-image {
    margin-bottom: 11px;
  }
}

// Content type Events style
.node-type-events {
  .field-name-body {
    margin-top: 27px;
  }
  .field-name-field-date-event,
  .field-name-field-text-event-time,
  .field-name-field-term-event-location {
    border-left: 6px solid transparent;
    margin-left: 33px;
    padding-left: 9px;
  }
}

#main #content div[id*=block-views-events-events-block] > h3 {
  padding-right: 120px;
}

