.header-tabs {
  background-color: blue;
  color: white;
  text-align: center;
  width: 200px;
  h2 {
    margin: 0;
    padding: 8px 8px;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
  }
}
