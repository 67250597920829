.content-top {
  overflow: hidden;
  // margin-bottom: 50px;
}

.paging-info {
    position: absolute;
    bottom: 30px;
    z-index: 20;
    color: white;
    font-size: 15px;
    font-weight: bold;
    @include breakpoint(0 $width--medium) {
      position: static;
    }
}

.slick-slider {
  margin-bottom: 0;
}

.slick-slide:focus { outline: none; }

.bg-slider-image {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.field-name-field-pg-slider-link {
  margin-top: 10px;
  a {
    border: 1px solid #fff;
    padding: 4px 10px;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: .14em;
    color: #fff;
    text-transform: uppercase;
    border-radius: 2px;
    display: inline-block;
    
    &:hover {
      text-decoration: none;
      background-color: #FFF;
      color: $color--navy;
    }
  }
}

.field-name-field-pg-slider-body {
  font-size: 15px;
  @include breakpoint($bp--medium-up) {
    font-size: 18px;
  }
}
.slider-image-full {
    .field-name-field-pg-slider-body {
        display: none;
    }
    &.page-node-edit .field-name-field-paragraph-sliders .form-wrapper {
        min-height: 0;
    }
}
.paragraphs-items-field-paragraph-sliders {
  .slick-prev,
  .slick-next {
    border: 2px solid white;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    color: #FFF;
    outline: none;
    background: transparent;
    z-index: 5;
    display: none !important;
    @include breakpoint($bp--medium-up) {
      display: inline-block !important;
    }
    &:before {
      font-family: $font-awesome;
      color: white;
      font-size: 20px;
      opacity: 1;
      line-height: 48px;
      display: block;
    }
    &:hover:before {
      opacity: 0.75;
    }
  }
  .slick-prev:before {
    content: "\f053";
    text-indent: -3px;
  }
  .slick-next:before {
    content: "\f054";
    text-indent: 3px;
  }

  .slick-dots {
    li {
      width: 10px;
      height: 10px;
      margin: 0 6px;
      button{
        width: 10px;
        height: 10px;
        padding: 0;
        &:before {
          border-radius: 50%;
          width: 6px;
          height: 6px;
          border: 2px solid #fff;
          content: " ";
          opacity: 1;
        }
        &:hover:before {
          opacity: 0.75;
        }
      }
    }
    li.slick-active button:before {
      background-color: #fff;
      z-index: 800;
    }
  }
}

.group-slider-content {
  &::-webkit-scrollbar {
    display: none;
  }

  .field-name-field-pg-slider-title {
    font-weight: 300;
    font-size: 30px;
    .font--header--granjon &{
      font-family: $secondary-font;
      font-weight: 400;
    }
  }
}

// No Image Slider
.slider-no-image {
  .content-top {
    overflow: hidden;
    clear: both;
  }
}
.slider-no-image .region-content-top{
  @include breakpoint (0 $width--medium) {
    padding: 0;
  }
  max-width: 1320px;
  margin: 0 auto;
  padding-left: 0px;
  padding-right: 50px;
  margin-bottom: 50px;
  #block-fieldblock-node-page-default-field-paragraph-sliders {
    position: relative;
    &:after {
      content: " ";
      position: absolute;
      width: 30px;
      height: 300px;
      z-index: 0;
      right: 0px;
      top: 0;
    }
    .group-slider-content {
      padding-left: 75px;
      padding-right: 75px;
      overflow: auto;
      // padding-top: 80px;
      // padding-bottom: 30px;
      color: #FFF;
      position: relative;
      // Primary Color
      //background-color: $color--orange;
      height: 260px;
      @include breakpoint (0 $width--medium) {
        padding-left: 30px;
        padding-top: 50px;
        padding-right: 60px;
      }
      .group-slider-inside {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        max-width: 68%;
      }
      .field-items {
        margin-bottom: 0;
      }
       .field-name-field-pg-slider-title {
        // position: relative;
        // z-index: 12;
        letter-spacing: -.02em;
        line-height: 1em;
        display: block !important;
        margin-bottom: 20px;
        @include breakpoint($bp--medium-up) {
          font-size: 48px;
        }
      }
      .field-name-field-pg-slider-body {
        font-family: $main-font;
        margin-bottom: 20px;
        // display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        max-height: 40px;
        @include breakpoint($bp--medium-up) {
          max-height: 55px;
        }
      }
    }
    .field-name-field-pg-slider-image {
      // background: url('../img/cell-roots.png') no-repeat 91.9%;
      height: 40px;
      width: 100%;
      float:right;
      margin-left: 90%;
      position: relative;
      .bg-slider-image {
        background-image: url('../img/cell-roots.png');
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        top: 0;
        width: 905px;
        max-width: 100%;
      }
      img {
        display: none;
        height: 500px;
      }
    }
    .slick-dots {
        bottom: 45px;
    }
    .slick-next {
        right: 60px;
        top: 80px;

    }
    .slick-prev {
        right: 130px;
        top: 80px;
        left: auto;
    }
    .paging-info {
        bottom: 15px;
        right: 60px;
        @include breakpoint(0 $width--medium) {
          position: absolute;
        }
    }
  }
}

// Image in back slider

.slider-image-behind .region-content-top{
  max-width: 1320px;
  margin: 0 auto;
  margin-bottom: 50px;
  @include breakpoint($bp--960-up) {
    padding-left: 25px;
    padding-right: 50px;
  }
  .slick-slide {
    position: relative;
    min-height: 23px;
  }
  .slick-dots {
      bottom: 60px;
  }
  .paging-info {
    bottom: 0;
    right: 0;
    @include breakpoint($bp--medium-up) {
        bottom: 5px;
        right: 10px;
    }
    @include breakpoint(0 $width--medium) {
      margin-top: 20px;
    }
  }
  #block-fieldblock-node-page-default-field-paragraph-sliders {
    //height: 500px;
    position: relative;
    .paragraphs-items > .field > .field-items > .slick-list >
    .slick-track > .field-item {
      &:before {
        content: " ";
        position: absolute;
        z-index: 2;
        overflow: visible;
        margin-left: 45px;
        margin-top: 20px;
        width: 100%;
        bottom: 0px;
        top: 0;

        @include breakpoint($bp--960-up) {
          width: 735px;
          height: 420px;
          top: 40px;
          bottom: -15px;
          margin-top: 0px;
          margin-bottom: 0;
        }
      }
      &:after {
        content: " ";
        position: absolute;
        z-index: 0;

        margin-left: 0;
        width: 100%;
        right: 30px;
        bottom: 30px;
        height: 370px;
        display: none;

        @include breakpoint($bp--960-up) {
          height: 420px;
          width: 735px;
          bottom: 0px;
          right: auto;
          display: block;
        }
      }
    }

    .slick-prev {
        left: 630px;
        top: 70px;
        top: 70px;

        @include breakpoint($width--medium) {
            top: 435px;
            left: 605px;
        }
        @include breakpoint($width--960) {
            top: 70px;
        }
    }
    .slick-next {
        left: 705px;
        top: 70px;

        @include breakpoint($width--medium) {
            top: 435px;
            left: 680px;
        }
        @include breakpoint($width--960) {
            top: 70px;
        }
    }
    .group-slider-content {
      padding: 20px 20px 20px 40px;
      height: 340px;
      overflow: auto;
      margin-top: 0px;
      position: relative;
      z-index: 10;
      top: -15px;
      margin-bottom: 15px;
      margin-right: 30px;
      @include breakpoint($bp--960-up) {
        position: absolute;
        margin-top: 60px;
        padding-left: 100px;
        width: 740px;
        margin-right: 0;
        top: 40px;
      }
      &::-webkit-scrollbar {
        display: none;
      }
      .group-slider-inside {
        &::-webkit-scrollbar {
          display: none;
        }

          @include breakpoint($bp--960-up) {
            max-height: 235px;
            overflow-y: auto;
            padding-right: 17px;
            position: absolute;
            top: 30px;
            bottom: 0;
            left: 100px;
            // right: -17px;
        }
      }

      .field-items {
        margin-bottom: 0;
      }
       .field-name-field-pg-slider-title {
        color: #FFF;
        letter-spacing: -.02em;
        line-height: 1em;
        margin-bottom: 15px;
        @include breakpoint($bp--960-up) {
          font-size: 48px;
        }
      }
      .field-name-field-pg-slider-body {
        color: #FFF;
        font-family: $main-font;
        margin-bottom: 20px;
      }
    }
    .field-name-field-pg-slider-image {
      max-height: 435px;
      position: relative;
      float: none;
      width: 100%;
      height: 100%;
      z-index: 3;

      @include breakpoint($bp--960-up) {
        min-height: 500px;
        max-width: 745px;
        float:right;
        width: auto;
        height: auto;
        z-index: 1;
      }
      .bg-slider-image {
        height: 435px;
        @include breakpoint($bp--960-up) {
          width: 735px;
        }
      }
    }
  }
}


// Image left Slider

.slider-image-left .region-content-top {
  max-width: 1320px;
  height: auto;
  margin: 0 auto;
  margin-bottom: 50px;
  // background-color: red;
  position: relative;
  @include breakpoint($bp--960-up) {
    padding-left: 25px;
    padding-right: 50px;
  }
  .paging-info {
    bottom: 50px;
    @include breakpoint(0 $width--medium) {
      margin-top: 20px;
    }
  }
  .slick-slide {
    min-height: 23px;
  }
  #block-fieldblock-node-page-default-field-paragraph-sliders {
    // height: 500px;
    position: relative;
    .slick-prev {
      left: 74%;
      @include breakpoint(1270px) {
        left: 71%;
      }
      top: 40px;
    }
    .slick-next {
      left: 82%;
      @include breakpoint(1270px) {
        left: 78%;
      }
      top: 40px;
      right: 0;
    }
    .slick-prev,
    .slick-next {
      @include breakpoint($bp--medium-up) {
        display: none !important;
      }
      @include breakpoint($bp--960-up) {
        display: block !important;
      }
    }
    &:before {
      height: 90%;
      content: " ";
      position: absolute;
      z-index: -1;
      overflow: visible;
      left: -200%;
      right: -200%;
      top: 0;
      display: none;
      @include breakpoint($bp--960-up) {
        bottom: 25px;
        height: 475px;
        display: block;
      }
    }
    &:after {
      content: " ";
      position: absolute;
      width: 213px;
      height: 30px;
      left: 0px;
      bottom: -30px;
      @include breakpoint($bp--960-up) {
        height: 500px;
        width: 145px;
        left: 67.8%;
        top: 0;
        display: block;
        z-index: -22;
      }
    }
    .group-slider-content {
      position: static;
      display: block;
      float: left;
      color: #FFF;
      max-height: 280px;
      height: 280px;
      overflow: auto;
      width: 100%;
      padding: 30px;
      @include breakpoint($bp--960-up) {
        max-height: 320px;
        height: 320px;
        margin-top: 90px;
        width: 29%;
        padding: 20px 20px 10px 40px;
        margin-bottom: 30px;
        .slick-slider {
          margin-bottom: 0;
        }
      }
      &:before {
        content: " ";
        position: absolute;
        z-index: -1;
        left: -200%;
        right: -200%;
        max-width: 100%;

        margin-top: -12%;
        bottom: 0;
        display: none;
        @include breakpoint($bp--960-up) {
          margin-top: 0;
          bottom: auto;
          display: block;
        }
      }
      &:after {
        content: " ";
        position: absolute;
        width: 145px;
        height: 500px;
        z-index: -22;
        top: 30px;
        left: 0;
        @include breakpoint($bp--960-up) {
          left: 845px;
          top: 0;
        }
      }
      .field-name-field-pg-slider-title  {
        margin-bottom: 10px;
        .field-item {
          line-height: 1em;
          font-size: 30px;
          @include breakpoint($bp--960-up) {
            font-size: 44px;
          }
        }
      }
    }
    .field-name-field-pg-slider-link {
      margin-top: 20px;
    }
    .field-name-field-pg-slider-image {
      max-height: 250px;
      width: 100%;
      height: 100%;
      @include breakpoint($bp--small-up) {
        max-height: 412px;
      }
      @include breakpoint($bp--960-up) {
        float: left;
        width: 71%;
        height: 100%;
        max-width: 845px;
        max-height: 500px;
      }
      .bg-slider-image {
        width: 100%;
        height: 250px;
        @include breakpoint($bp--small-up) {
          height: 412px;
        }
        @include breakpoint($bp--960-up) {
          height: 500px;
        }
      }
    }
  }
}

// Image Right Slider

.slider-image-right .region-content-top {
  max-width: 1320px;
  margin: 0 auto;
  margin-bottom: 50px;
  @include breakpoint($bp--medium-up) {
    padding-left: 25px;
    padding-right: 50px;
  }
  .slick-slide {
    min-height: 23px;
    position: relative;
  }
  #block-fieldblock-node-page-default-field-paragraph-sliders {
    height: 500px;
    position: relative;
    @include breakpoint(0 $width--medium) {
      height:  auto;
    }
    .slick-slider {
      @include breakpoint(0 $width--medium) {
        margin-bottom: 0;
      }
    }
    .slick-prev {
      left: 32px;
      top: 150px;
    }
    .slick-next {
      left: 100px;
      top: 150px;
    }
    &:before {
      content: " ";
      position: absolute;
      height: 440px;
      z-index: -1;
      overflow: visible;
      top: 30px;
      left: -200%;
      right: -200%;
      @include breakpoint(0 $width--medium) {
        top: 0;
        height: 100%;
      }
    }
    &:after {
      content: " ";
      position: absolute;
      width: 42%;
      height: 395px;
      z-index: -2;
      left: 0px;
      bottom: 0;
      @include breakpoint(0 $width--medium) {
        display: none;
      }
    }
    .group-slider-content {
      position: absolute;
      width: 42%;
      height: 395px;
      top: 105px;
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 100px;
      padding-bottom: 35px;
      color: white;
      z-index: 20;
      overflow: auto;
      @include breakpoint(0 $width--medium) {
        position: relative;
        width: 100%;
        padding: 30px;
        height: 100%;
        top: 0px;
      }
      .group-slider-inside {
          overflow: auto;
          height: 100%;
          padding-bottom: 30px;
          &::-webkit-scrollbar {
            display: none;
          }

        //   @include breakpoint($bp--960-up) {
        //     max-height: 265px;
        //     overflow-y: auto;
        //     padding-right: 40px;
        //     position: absolute;
        //     top: 100px;
        //     bottom: 0;
        //     left: 30px;
        //     right: -17px;
        // }
      }
      .field-name-field-pg-slider-title {
        display: relative;
        z-index: 21;
        line-height: 1em;
        margin-bottom: 10px;
        @include breakpoint($bp--medium-up) {
          font-size: 44px;
        }
      }
      .field-name-field-pg-slider-body {
        margin-bottom: 15px;
      }
    }
    .field-name-field-pg-slider-image {
      padding-right: 30px;
      padding-left: 30px;
      @include breakpoint($bp--medium-up) {
        max-width: 748px;
        max-height: 500px;
        float: right;
        margin-right: 10px;
        padding: 0;
      }
      .bg-slider-image {
        height: 250px;
        @include breakpoint($bp--small-up) {
          height: 386px;
        }
        @include breakpoint($bp--medium-up) {
          height: 500px;
          width: 748px;
        }
      }
    }
  }
}


// // Transparent overlay slider

.slider-image-full .content-top {
  background-color: rgba(147, 147, 147, 0.9);
  color: rgba(147, 147, 147, 0.9);
  max-height: 500px;
  margin-top: 0px;
  margin-bottom: 50px;
  @include breakpoint(0 $width--medium) {
    max-height: none;
    @include clearfix;
    margin-top: 0;
  }
}

.slider-image-full .region-content-top {
  max-width: 1280px;
  margin-bottom: 50px;
  margin: 0 auto;
  margin-bottom: 0;
  @include breakpoint($bp--medium-up) {
   margin-bottom: 50px;
  }

  #block-fieldblock-node-page-default-field-paragraph-sliders {

    .paragraphs-item-slider {
      position: relative;
      height: 385px;
      @include breakpoint($bp--medium-up) {
        height: 500px;
      }
    }

    .group-slider-content {
      margin: 0 auto;
      z-index: 22;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      overflow: auto;
      max-height: 385px;
      padding: 60px 50px;
      width: 100%;
      @include breakpoint($bp--medium-up) {
        max-height: 500px;
        padding: 60px 0px;
        max-width: 617px;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .slick-track .field-item {
        border-left: 1em solid transparent;
        border-right: 1em solid transparent;
      }
      .field-name-field-pg-slider-title {
        position: relative;
        color: white;
        line-height: 1;
        text-align: center;
        @include breakpoint($bp--medium-up) {
          font-size: 50px;
        }
      }
      .field-name-field-pg-slider-body {
        color: #FFF;
        position: relative;
        z-index: 12;
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
        padding: 20px 0px;
        display: none;
        // @include breakpoint($bp--medium-up) {
        //   display: block;
        // }
      }
      .field-name-field-pg-slider-link {
        text-align: center;
      }
    }
    .field-name-field-pg-slider-image {
      position: absolute;
      width: 100%;
      height: 100%;
      &:before {
        content: ' ';
        position: absolute;
        left: 0;
        right: 0;
        max-height: 500px;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 10;
        width: 100%;
        height: 100%;
      }
      .bg-slider-image {
        height: 385px;
        @include breakpoint($bp--medium-up) {
          height: 500px;
        }
      }
    }
    .slick-prev {
      left: 30px;
    }
    .slick-next {
      right: 30px;
    }
    .slick-prev,
    .slick-next {
      top: 50%;
      margin-top: -25px;
    }
    .slick-dots {
      bottom: 23px;
    }
  }
}

// Seconardy/Tertiary Slider
.slider-secondary {
  .region-content-top {
    margin: 20px auto 50px auto;
    @include breakpoint($bp--medium-up) {
      height: 235px;
      max-width: 1280px;
      margin-bottom: 75px;
    }

    .field-name-field-pg-slider-image {
      margin-top: -20px;
      @include breakpoint($bp--medium-up) {
        float: right;
        width: 50%;
      }
      .bg-slider-image {
        width: 100%;
        height: 300px;
        @include breakpoint($bp--small-up) {
          height: 400px;
        }
        @include breakpoint($bp--medium-up) {
          height: 276px;
          width: 430px;
        }
      }
    }
    .group-slider-content {
      padding: 18px 15px;
      width: 100%;

      @include breakpoint($bp--medium-up) {
        padding: 23px 23px 23px 50px;
        height: 235px;
        overflow: auto;
        width: 50%;
      }
      @include breakpoint(1320px) {
        padding: 23px 23px 23px 30px;
      }

      .field-name-field-pg-slider-title {
        color: #FFF;
      }
      .field-name-field-pg-slider-body {
        color: #FFF;
        margin-bottom: 5px;
      }
    }
  }
}
