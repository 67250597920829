a[class*="btn--"] {
  background: $color--blue;
  color: $color--white;
  font-family: $main-font;
  &:hover {
    background: $color--blue--tint1;
  }
  &:active {
    background: $color--grey2--tint2;
    color: $color--blue;
  }
}

.btn {
  //Block
  display: inline-block;
  padding: .5rem 2rem;
  border-radius: 3px;
  background-color: $color--blue;
  color: $color--white;
  border: 1px solid $color--blue;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1px;
  text-decoration: none;
  &:hover {
    cursor: pointer;
    background-color: $color--white !important;
    color: $color--blue;
    border: 1px solid $color--blue;
  }
}

a.btn--small {
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 4px;
  padding: 5px 20px;
  letter-spacing: 1.2px;
  display: inline-block;
}
a.btn--large {
  font-size: 18px;
  padding: 10px 25px;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  letter-spacing: 1.2px;
  border-radius: 4px;
  display: inline-block;
  max-width: 100%;
}
a.btn--white--underline {
  background: transparent;
  color: $color--blue;
  border: 1px solid $color--blue;
  &:hover {
    color: $color--blue;
    border: 1px solid $color--blue;
    background: $color--grey2--tint2;
  }
  &:active {
    background: $color--blue;
    color: $color--white;
    border: 1px solid $color--blue;
  }
}
a.btn--blue {
  color: $color--white;
  background: $color--blue;
  &:hover {
    background: $color--blue--tint1;
  }
  &:active {
    background: $color--grey2--tint2;
    color: $color--blue;
  }
}
a.btn--more--white {
  border: 1px solid rgba(255,255,255,0.3);
  color: $color--white;
  background: transparent;
  text-decoration: none !important;
  // width: 100%;
  &:hover {
    background: rgba(255,255,255,0.3);
  }
  &:active {
    background: rgba(255,255,255,0.3);
    color: white !important;
  }
}

a.btn--more--reverse {
  color: $color--white;
  background: $color--navy;
  text-decoration: none !important;
  text-transform: uppercase;
  &:hover {
    background: $color--navy--tint1;
  }
}
a.btn--full-width {
  width: 100%;
}

a.btn--purple {
  color: $color--white;
  background: $color--purple;
  text-decoration: none !important;
  // width: 100%;
  &:hover {
    background: $color--purple--tint1;
  }
  &:active {
    background: $color--purple--tint2;
    color: white !important;
  }
}
.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a.btn--giving {
    color: white;

}
a.btn--giving:after {
  content: "\f004";
  margin-left: 10px;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

//[ 'Giving Small Blue', 'btn--small btn--blue btn--giving' ],
//[ 'Giving Small Blue Full Width', 'btn--small btn--blue btn--full-width btn--giving' ],
//[ 'Giving Small Purple', 'btn--small btn--purple btn--giving' ],
//[ 'Giving Small Purple Full Width', 'btn--small btn--purple btn--full-width btn--giving' ],
//
//[ 'Giving Blue Large', 'btn--large btn--blue btn--giving' ],
//[ 'Giving Purple Large', 'btn--large btn--purple btn--giving fa' ],
//[ 'Giving Blue Full Width', 'btn--large btn--blue btn--full-width btn--giving' ],
//[ 'Giving Purple Full Width', 'btn--large btn--purple btn--full-width btn--giving' ],
a.btn--giving {
  border-radius: 3px;
  letter-spacing: 1px;

  &.btn--large {
    padding: 24px;

  }

  &.btn--small {
    padding: 18px;

  }

}